import {
    executeDeleteRequest,
    executeGetRequest,
    executePostRequest
} from '../../apis/poolManager';import { CREATE_RESULT_CYCLIST, DELETE_RESULT_CYCLIST, FETCH_RESULT_CYCLISTS } from './types';

export const fetchResultCyclists = (tournamentId) => async (dispatch) => {
    const response = await executeGetRequest(`/result_cyclists?result.rankingType.ranking.tournament=${tournamentId}`);

    dispatch({
        type: FETCH_RESULT_CYCLISTS,
        payload: response.data['hydra:member']
    });
}

export const createResultCyclist = formValues => async (dispatch, getState) => {
    const response = await executePostRequest(`/result_cyclists`, {
        ...formValues
    })

    dispatch({
        type: CREATE_RESULT_CYCLIST,
        payload: response.data
    });
}

export const deleteResultCyclist = resultCyclist => async dispatch => {
    await executeDeleteRequest(`/result_cyclists/${resultCyclist.id}`);

    dispatch({
        type: DELETE_RESULT_CYCLIST,
        payload: resultCyclist['@id']
    });
}
