import { mapKeys, omit } from 'lodash';
import { CALCULATE_RESULT, CREATE_RESULT, DELETE_RESULT, EDIT_RESULT, FETCH_RESULTS } from '../actions/result/types';

const INITIAL_STATE = {}

const resultReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_RESULTS:
            return {...state, ...mapKeys(action.payload, '@id')};
        case CREATE_RESULT:
        case CALCULATE_RESULT:
        case EDIT_RESULT:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_RESULT:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default resultReducer;
