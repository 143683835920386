import { createMuiTheme } from '@material-ui/core/styles';
import { blue, lightBlue } from '@material-ui/core/colors';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: lightBlue,
        background: {
            default: "#eeeeee"
        }
    },
    overrides: {
        MuiInputLabel: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiInput: {
            root: {
                fontSize: '0.875rem',
            },
        },
    }
})

export default theme;
