import React from 'react';
import useCyclists from '../../../../hooks/useCyclists';
import { Avatar, TableCell, TableRow } from '@material-ui/core';
import useTeams from '../../../../hooks/useTeams';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteResultCyclist } from '../../../../actions/resultCyclist';

const ResultCyclistTableRow = ({resultCyclist, allowDelete}) => {
    const dispatch = useDispatch();
    const {findCyclistById} = useCyclists();
    const {findById} = useTeams();
    
    const cyclist = findCyclistById(resultCyclist.cyclist);
    if (!cyclist) {
        return 'Loading...';
    }

    const team = findById(cyclist.team);
    if (!team) {
        return 'Loading...';
    }

    const onDeleteClicked = () => {
        dispatch(deleteResultCyclist(resultCyclist));
    }

    return (
        <TableRow hover>
            <TableCell>{resultCyclist.position}</TableCell>
            <TableCell>
                <Avatar style={{height: '25px', width: '25px'}} alt={team.name} src={team.imageUrl}/>
            </TableCell>
            <TableCell>{cyclist.fullName}</TableCell>
            {
                allowDelete && <TableCell>
                    <DeleteIcon
                        cursor="pointer"
                        color="primary"
                        onClick={() => onDeleteClicked(resultCyclist)}
                    />
                </TableCell>
            }
        </TableRow>
    );
}

export default ResultCyclistTableRow;
