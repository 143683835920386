import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import RankingForm from './rankingForm';
import { useParams } from 'react-router-dom';
import { editRanking, fetchRanking } from '../../../actions/ranking';

const RankingEdit = () => {
    const {rankingId} = useParams();
    const ranking = useSelector(state => state.rankings[`/rankings/${rankingId}`]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRanking(rankingId));
    }, []);

    const onSubmit = data => {
        dispatch(editRanking(rankingId, data));
    }

    if (!ranking) {
        return <div>Loading</div>
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>{ranking.name}</h1>
            <RankingForm onSubmit={onSubmit} defaultValues={ranking}/>
        </Paper>
    );
};

export default RankingEdit;
