import { mapKeys, omit } from 'lodash';
import { CREATE_RESULT_CYCLIST, DELETE_RESULT_CYCLIST, FETCH_RESULT_CYCLISTS } from '../actions/resultCyclist/types';

const INITIAL_STATE = {}

const resultCyclistReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_RESULT_CYCLISTS:
            return {...state, ...mapKeys(action.payload, '@id')};
        case CREATE_RESULT_CYCLIST:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_RESULT_CYCLIST:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default resultCyclistReducer;
