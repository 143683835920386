import { mapKeys } from 'lodash';
import { FETCH_RANKING_TYPES } from '../actions/rankingType/types';

const INITIAL_STATE = {}

const rankingTypeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_RANKING_TYPES:
            return {...state, ...mapKeys(action.payload, '@id')};
        default:
            return state;
    }
}

export default rankingTypeReducer;
