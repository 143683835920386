import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Grid } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const CyclistForm = ({defaultValues, onSubmit}) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const submit = (data) => {
        onSubmit(data);
        reset();
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        {...register('firstName')}
                        name="firstName"
                        label="Voornaam"
                        error={!!errors?.firstName}
                        helperText={errors?.firstName?.message}
                        style={{marginBottom: '20px'}}
                        size="small"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        {...register('lastName')}
                        name="lastName"
                        label="Achternaam"
                        error={!!errors?.lastName}
                        helperText={errors?.lastName?.message}
                        style={{marginBottom: '20px'}}
                        size="small"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register('country')}
                        style={{marginBottom: '20px'}}
                        name="country"
                        label="Land"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Button size="small" type="submit" variant="contained" color="primary">
                Toevoegen
            </Button>
        </form>
    );
};

const schema = yup.object().shape({
    firstName: yup.string().required('Naam is verplicht'),
    lastName: yup.string().required('Afkorting is verplicht'),
});

export default CyclistForm
