import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Switch, FormControlLabel, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDate } from '../../../helpers/dateHelper';
import * as yup from "yup";

const CompetitorForm = ({onSubmit, defaultValues}) => {
    defaultValues = {
        ...defaultValues,
        dateOfBirth: defaultValues ? formatDate(defaultValues.dateOfBirth, 'YYYY-MM-DD') : null,
    }

    const { register, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const onSubmitClick = (data) => {
        onSubmit({
            ...data,
            dateOfBirth: data.dateOfBirth === '' ? null : data.dateOfBirth
        });
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitClick)}>
            <TextField
                name="name"
                label="Naam"
                style={{marginBottom: '20px'}}
                size="small"
                fullWidth
                required
                {...register('name')}
                error={!!errors?.name}
                helperText={errors?.name?.message}
            />
            <TextField
                name="email"
                label="Email"
                {...register('email')}
                style={{marginBottom: '20px'}}
                size="small"
                fullWidth
                required
            />
            <TextField
                name="teamName"
                label="Team naam"
                {...register('teamName')}
                style={{marginBottom: '20px'}}
                size="small"
                fullWidth
                required
            />
            <TextField
                name="city"
                label="Plaats"
                {...register('city')}
                style={{marginBottom: '20px'}}
                size="small"
                fullWidth
                required
            />
            <TextField
                name="dateOfBirth"
                label="Geboortedatum"
                {...register('dateOfBirth')}
                style={{marginBottom: '20px'}}
                type="date"
                size="small"
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
            />
            <FormControlLabel
                label={<Typography variant="body2">Betaald</Typography>}
                style={{width: '100%', marginBottom: '20px'}}
                control={
                    <Controller
                        name="payed"
                        render={({ field }) => (
                            <Switch
                                {...field}
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                            />
                        )}
                        control={control}
                    />
                }
            />

            <Button size="small" type="submit" variant="contained" color="primary">
                Opslaan
            </Button>
        </form>
    );
};

const schema = yup.object().shape({
    name: yup.string().required('Naam is verplicht'),
    teamName: yup.string().required('Team naam is verplicht'),
    email: yup.string().required('Email is verplicht')
});

export default CompetitorForm;
