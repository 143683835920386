import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import TeamForm from './teamForm';
import { createTeam } from '../../../actions/team';

const TeamCreate = () => {
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(createTeam(data));
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>Aanmaken</h1>
            <TeamForm onSubmit={onSubmit} />
        </Paper>
    );
};

export default TeamCreate;
