import { mapKeys, omit } from 'lodash';
import {
    CREATE_SUBLEAGUE,
    DELETE_SUBLEAGUE,
    EDIT_SUBLEAGUE,
    FETCH_SUBLEAGUE,
    FETCH_SUBLEAGUES
} from '../actions/subleague/types';

const INITIAL_STATE = {}

const subleagueReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_SUBLEAGUES:
            return {...state, ...mapKeys(action.payload, '@id')};
        case FETCH_SUBLEAGUE:
        case EDIT_SUBLEAGUE:
        case CREATE_SUBLEAGUE:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_SUBLEAGUE:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default subleagueReducer;
