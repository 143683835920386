import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useSubleagues = () => {
    const state = useSelector((state) => state.subleagues);
    const selectedTournament = useSelector((state) => state.selectedTournament);
    const [subleagues, setSubleagues] = useState(Object.values(state));

    useEffect(() => {
        setSubleagues(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[`/subleagues/${id}`];
    }

    function findForCurrentTournament() {
        return subleagues.filter(subleague => subleague.tournament == selectedTournament['@id'])
    }

    return {
        subleagues,
        findById,
        findForCurrentTournament
    };
};

export default useSubleagues;
