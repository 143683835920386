import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useRankingTypes = () => {
    const state = useSelector((state) => state.rankingTypes);
    const selectedTournament = useSelector((state) => state.selectedTournament);
    const [rankingTypes, setRankingTypes] = useState(Object.values(state));

    useEffect(() => {
        setRankingTypes(Object.values(state));
    }, [state]);

    function findRankingTypeById(id) {
        return state[id];
    }

    function findRankingTypesForCurrentTournament() {
        return rankingTypes.filter(rankingType => rankingType.tournament === selectedTournament['@id'])
    }

    return {
        rankingTypes,
        findRankingTypeById,
        findRankingTypesForCurrentTournament,
    };
};

export default useRankingTypes;
