import React, { useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchCompetitorCyclists } from '../../../../actions/competitorCyclist';
import useCompetitorCyclists from '../../../../hooks/useCompetitorCyclists';
import CompetitorCyclistTableRow from './competitorCyclistTableRow';
import useCyclists from '../../../../hooks/useCyclists';
import { useSelector } from 'react-redux';

const CompetitorCyclistList = ({competitor, competitorCyclist, expanded, allowDelete, onSelected, onDeleteClicked, selected}) => {
    const dispatch = useDispatch();
    const {findByCompetitor} = useCompetitorCyclists();
    const {findCyclistById} = useCyclists();
    const tournament = useSelector(state => state.selectedTournament);
    let nr = 1;

    useEffect(() => {
        dispatch(fetchCompetitorCyclists(competitor.id));
    }, [competitor])

    const competitorCyclists = findByCompetitor(competitor);
    if (!competitorCyclists) {
        return <div>Loading...</div>
    }

    return (
        <>
            <TableContainer style={{position: "sticky", top: '75px'}} component={Paper}>
                <Table aria-label="simple table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell><b>Team</b></TableCell>
                            <TableCell><b>Naam</b></TableCell>
                            {expanded && <TableCell><b>Ingevoerd</b></TableCell>}
                            <TableCell><b>Reserve</b></TableCell>
                            {allowDelete && <TableCell width="15px"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {competitorCyclists.map((competitorCyclistRow, index) => {
                            const cyclist = findCyclistById(competitorCyclistRow.cyclist);
                            let background = '';

                            if (cyclist) {
                                if (!cyclist.active) {
                                    background = '#ffebee';
                                } else if (nr <= tournament.nrOfCyclists) {
                                    nr++;
                                    background = '#e8f5e9';
                                }
                            } else {
                                background = '#CDE5FE';
                            }

                            return (
                                <CompetitorCyclistTableRow
                                    background={background}
                                    expanded={expanded}
                                    cyclist={cyclist}
                                    competitorCyclist={competitorCyclistRow}
                                    onSelected={onSelected}
                                    selected={competitorCyclist?.id === competitorCyclistRow.id}
                                    allowDelete={allowDelete}
                                    onDeleteClicked={onDeleteClicked}
                                    key={index}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CompetitorCyclistList;
