import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import CompetitorForm from './competitorForm';
import { createCompetitor } from '../../../actions/competitor';

const CompetitorCreate = () => {
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(createCompetitor(data));
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>Aanmaken</h1>
            <CompetitorForm onSubmit={onSubmit} />
        </Paper>
    );
};

export default CompetitorCreate;
