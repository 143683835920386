import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import useCompetitors from '../../../../hooks/useCompetitors';
import ControlledAutocomplete from '../../../shared/ControlledAutocomplete';

const SubleagueCompetitorForm = ({onSubmit}) => {
    const {handleSubmit, control, reset} = useForm();

    const {findForCurrentTournament} = useCompetitors();
    const competitors = findForCurrentTournament();

    const options = [];
    competitors.map((competitor) => {
        return options.push({value: competitor['@id'], text: `${competitor.name} (${competitor.teamName})`});
    });

    const onSubmitClick = (data) => {
        onSubmit(data);
        reset();
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitClick)}>
            <ControlledAutocomplete
                control={control}
                name="competitor"
                options={options}
                getOptionLabel={(option) => option.text}
                defaultValue={''}
                renderInput={(params) => {
                    return <TextField
                        {...params}
                        label="Deelnemers"
                        style={{marginBottom: '20px'}}
                    />
                }}
            />
            <Button size="small" type="submit" variant="contained" color="primary">
                Opslaan
            </Button>
        </form>
    )
};

export default SubleagueCompetitorForm;
