import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Button, Link, Box } from '@material-ui/core';
import {
    DirectionsBike as DirectionsBikeIcon,
    FilterHdr as FilterHdrIcon,
    Reorder as ReorderIcon,
    Group as GroupIcon,
    ExitToApp as ExitToAppIcon,
    Dehaze as DehazeIcon
} from '@material-ui/icons';
import MenuListComponent from './menu/MenuList';
import { fetchTournaments, tournamentSelected } from '../actions/tournament';
import history from './history';
import {
    createListRankingRoute,
    createListResultRoute,
    createListStageRoute,
    createListTeamRoute
} from '../routes';
import { logoutUser } from '../actions/auth';

const Header = () => {
    const dispatch = useDispatch();
    const tournaments = useSelector(state => Object.values(state.tournaments));
    const selectedTournament = useSelector(state => state.selectedTournament);

    useEffect(() => {
        dispatch(fetchTournaments());
    }, []);

    useEffect(() => {
        if (!selectedTournament && tournaments.length > 0) {
            dispatch(tournamentSelected(tournaments[0]));
        }
    });

    const onOptionSelected = (option) => {
        dispatch(tournamentSelected(option));
        history.push(`/tournament/${option.id}/dashboard`);
    }

    const onLogoutClick = () => {
        dispatch(logoutUser());
    }

    if (!selectedTournament) {
        return <div></div>
    }

    return (
        <div>
            <AppBar>
                <Toolbar>
                    <Box display='flex' flexGrow={1}>
                        <MenuListComponent
                            value={selectedTournament}
                            options={tournaments}
                            onOptionSelected={onOptionSelected}
                        ></MenuListComponent>
                        <Link underline="none" component={RouterLink} color="inherit"
                              to={createListTeamRoute(selectedTournament.id)}>
                            <Button color="inherit">
                                <DirectionsBikeIcon style={{paddingRight: '5px'}}/>
                                Teams
                            </Button>
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit"
                              to={createListStageRoute(selectedTournament.id)}>
                            <Button color="inherit">
                                <FilterHdrIcon style={{paddingRight: '5px'}}/>
                                Etappes
                            </Button>
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit"
                              to={createListRankingRoute(selectedTournament.id)}>
                            <Button color="inherit">
                                <FilterHdrIcon style={{paddingRight: '5px'}}/>
                                Klassementen
                            </Button>
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit"
                              to={createListResultRoute(selectedTournament.id)}>
                            <Button color="inherit">
                                <DehazeIcon style={{paddingRight: '5px'}}/>
                                Uitslagen
                            </Button>
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit"
                              to={`/tournament/${selectedTournament.id}/subleagues`}>
                            <Button color="inherit">
                                <ReorderIcon style={{paddingRight: '5px'}}/>
                                Subleagues
                            </Button> </Link>
                        <Link underline="none" component={RouterLink} color="inherit"
                              to={`/tournament/${selectedTournament.id}/competitors`}>
                            <Button color="inherit">
                                <GroupIcon style={{paddingRight: '5px'}}/>
                                Deelnemers
                            </Button>
                        </Link>
                    </Box>
                    <Link underline="none" component={RouterLink} color="inherit"
                          to={`/logout`}>
                        <Button color="inherit" onClick={onLogoutClick}>
                            <ExitToAppIcon style={{paddingRight: '5px'}}/>
                        </Button>
                    </Link>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;
