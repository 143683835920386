import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableHead, TableRow, TableCell, Paper, TableBody, TableContainer, Avatar } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { deleteCyclist, editCyclist, fetchCyclists } from '../../../../actions/cyclist';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const CyclistList = ({selectedTeam, allowDelete}) => {
    const dispatch = useDispatch();
    const cyclists = useSelector(state => Object.values(state.cyclists).filter(cyclist => cyclist.team == `/teams/${selectedTeam.id}`));

    const onDeleteClicked = (cyclist) => {
        dispatch(deleteCyclist(cyclist));
    }

    const onToggleActive = (cyclist) => {
        dispatch(editCyclist(cyclist.id, {
            ...cyclist,
            active: !cyclist.active
        }));
    }

    if (!cyclists.length) {
        return <></>
    }

    return (
        <>
            <TableContainer style={{position: "sticky", top: '75px'}} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell><b>Naam</b></TableCell>
                            <TableCell><b>Land</b></TableCell>
                            <TableCell><b>Actief</b></TableCell>
                            {allowDelete && <TableCell></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cyclists.map((cyclist) => (
                            <TableRow key={cyclist.id} hover>
                                <TableCell><Avatar style={{height: '25px', width: '25px'}} alt={selectedTeam.name}
                                                   src={selectedTeam.imageUrl}/></TableCell>
                                <TableCell>{cyclist.firstName + ' ' + cyclist.lastName}</TableCell>
                                <TableCell>{cyclist.country}</TableCell>
                                <TableCell>{cyclist.active ?
                                    <CheckIcon onClick={() => onToggleActive(cyclist)} /> :
                                    <ClearIcon onClick={() => onToggleActive(cyclist)} />}
                                </TableCell>
                                {
                                    allowDelete && <TableCell>
                                        <DeleteIcon
                                            cursor="pointer"
                                            color="primary"
                                            onClick={() => onDeleteClicked(cyclist)}
                                        />
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CyclistList;
