import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Fab, Link } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

const AddButton = ({to}) => {
    return (
        <Link underline="none" component={RouterLink} color="inherit" to={to}>
            <Fab color="primary" style={{position: 'fixed', bottom: '25px', left: 'auto', right: '25px'}}>
                <AddIcon/>
            </Fab>
        </Link>
    );
}

export default AddButton;
