import React from 'react';
import { Button, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import useResultCyclists from '../../../../hooks/useResultCyclists';
import { map } from 'lodash';
import { Controller } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import CyclistSelect from '../../../shared/CyclistSelect';

const ResultCyclistForm = ({result, onSubmit, defaultValues}) => {
    const {findByResult} = useResultCyclists();
    const resultCyclists = findByResult(result);
    const {handleSubmit, errors, control, reset} = useForm({ defaultValues });

    const positionOptions = [];
    const usedPositions = map(resultCyclists, 'position')

    for (let i = 1; i <= 10; i++) {
        if (usedPositions.includes(i)) {
            continue;
        }

        positionOptions.push({value: i, text: i});
    }

    const onSubmitClick = (data) => {
        onSubmit(data);
        reset();
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitClick)}>
            <FormControl
                fullWidth
                style={{marginBottom: '20px'}}
            >
                <InputLabel>Positie</InputLabel>
                <Controller
                    control={control}
                    name="position"
                    defaultValue={''}
                    render={({field}) => (
                        <Select
                            {...field}
                            error={!!errors?.position}
                        >
                            {positionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
                <FormHelperText error={true}>{errors?.rankingType && errors?.rankingType.message}</FormHelperText>
            </FormControl>
            <CyclistSelect control={control} />
            <Button type="submit" variant="contained" color="primary">
                Opslaan
            </Button>
        </form>
    )
};

export default ResultCyclistForm;
