import history from '../../components/history';
import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest
} from '../../apis/poolManager';
import { createListCompetitorRoute } from '../../routes';
import { CREATE_COMPETITOR, DELETE_COMPETITOR, EDIT_COMPETITOR, FETCH_COMPETITORS } from './types';

export const fetchCompetitors = (tournamentId) => async (dispatch) => {
    const response = await executeGetRequest(`/competitors?tournament=${tournamentId}`);

    dispatch({
        type: FETCH_COMPETITORS,
        payload: response.data['hydra:member']
    });
}

export const createCompetitor = (formValues) => async (dispatch, getState) => {
    const response = await executePostRequest(`/competitors`, {
        ...formValues,
        'tournament': `/tournaments/${getState().selectedTournament.id}`
    });

    dispatch({
        type: CREATE_COMPETITOR,
        payload: response.data
    });

    history.push(createListCompetitorRoute(getState().selectedTournament.id));
}

export const editCompetitor = (id, formValues) => async (dispatch, getState) => {
    const response = await executePatchRequest(`/competitors/${id}`, {
        ...formValues
    });

    dispatch({
        type: EDIT_COMPETITOR,
        payload: response.data
    });

    history.push(createListCompetitorRoute(getState().selectedTournament.id));
}

export const deleteCompetitor = (competitor) => async dispatch => {
    await executeDeleteRequest(`/competitors/${competitor.id}`);

    dispatch({
        type: DELETE_COMPETITOR,
        payload: competitor['@id']
    });
}
