export const LIST_TEAM_ROUTE = '/tournament/:tournamentId/teams';
export const CREATE_TEAM_ROUTE = '/tournament/:tournamentId/teams/create';
export const EDIT_TEAM_ROUTE = '/tournament/:tournamentId/teams/:teamId/edit';

export const LIST_STAGE_ROUTE = '/tournament/:tournamentId/stages';
export const CREATE_STAGE_ROUTE = '/tournament/:tournamentId/stages/create';
export const EDIT_STAGE_ROUTE = '/tournament/:tournamentId/stages/:stageId/edit';

export const LIST_RANKING_ROUTE = '/tournament/:tournamentId/rankings';
export const CREATE_RANKING_ROUTE = '/tournament/:tournamentId/rankings/create';
export const STANDING_RANKING_ROUTE = '/tournament/:tournamentId/rankings/:rankingId/standings';
export const EDIT_RANKING_ROUTE = '/tournament/:tournamentId/rankings/:rankingId/edit';

export const LIST_RESULT_ROUTE = '/tournament/:tournamentId/results';
export const CREATE_RESULT_ROUTE = '/tournament/:tournamentId/results/create';
export const STANDINGS_RESULT_ROUTE = '/tournament/:tournamentId/results/:resultId/standings';
export const EDIT_RESULT_ROUTE = '/tournament/:tournamentId/results/:resultId/edit';

export const LIST_SUBLEAGUE_ROUTE = '/tournament/:tournamentId/subleagues';
export const CREATE_SUBLEAGUE_ROUTE = '/tournament/:tournamentId/subleagues/create';
export const EDIT_SUBLEAGUE_ROUTE = '/tournament/:tournamentId/subleagues/:subleagueId/edit';

export const LIST_COMPETITOR_ROUTE = '/tournament/:tournamentId/competitors';
export const CREATE_COMPETITOR_ROUTE = '/tournament/:tournamentId/competitors/create';
export const EDIT_COMPETITOR_ROUTE = '/tournament/:tournamentId/competitors/:competitorId/edit';

export const createListTeamRoute = tournamentId => {
    return LIST_TEAM_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createEditTeamRoute = (tournamentId, teamId) => {
    return EDIT_TEAM_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':teamId', teamId);
}

export const createCreateTeamRoute = tournamentId => {
    return CREATE_TEAM_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createListStageRoute = tournamentId => {
    return LIST_STAGE_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createListRankingRoute = tournamentId => {
    return LIST_RANKING_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createCreateRankingRoute = tournamentId => {
    return CREATE_RANKING_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createEditRankingRoute = (tournamentId, rankingId) => {
    return EDIT_RANKING_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':rankingId', rankingId);
}

export const createStandingsRankingRoute = (tournamentId, rankingId) => {
    return STANDING_RANKING_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':rankingId', rankingId);
}

export const createCreateStageRoute = tournamentId => {
    return CREATE_STAGE_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createEditStageRoute = (tournamentId, stageId) => {
    return EDIT_STAGE_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':stageId', stageId);
}

// Result
export const createListResultRoute = tournamentId => {
    return LIST_RESULT_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createCreateResultRoute = tournamentId => {
    return CREATE_RESULT_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createStandingsResultRoute = (tournamentId, resultId) => {
    return STANDINGS_RESULT_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':resultId', resultId);
}

export const createEditResultRoute = (tournamentId, resultId) => {
    return EDIT_RESULT_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':resultId', resultId);
}

// Subleague
export const createListSubleagueRoute = tournamentId => {
    return LIST_SUBLEAGUE_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createCreateSubleagueRoute = tournamentId => {
    return CREATE_SUBLEAGUE_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createEditSubleagueRoute = (tournamentId, resultId) => {
    return EDIT_SUBLEAGUE_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':subleagueId', resultId);
}

// Competitor
export const createListCompetitorRoute = tournamentId => {
    return LIST_COMPETITOR_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createCreateCompetitorRoute = tournamentId => {
    return CREATE_COMPETITOR_ROUTE
        .replace(':tournamentId', tournamentId);
}

export const createEditCompetitorRoute = (tournamentId, competitorId) => {
    return EDIT_COMPETITOR_ROUTE
        .replace(':tournamentId', tournamentId)
        .replace(':competitorId', competitorId);
}
