import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Button, FormGroup, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import CyclistSelect from '../../shared/CyclistSelect';

const CompetitorFilter = ({onSubmit, defaultValues}) => {
    const {control, handleSubmit} = useForm({
        defaultValues,
    });

    const onSubmitClick = (data) => {
        onSubmit({
            ...data,
            payed: parseInt(data.payed),
            allCyclistsLinked: parseInt(data.allCyclistsLinked),
            cyclist: data.cyclist ? data.cyclist.value : null
        });
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitClick)}>
            <FormGroup style={{width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: '10px'}} row>
                <CyclistSelect style={{width: '200px', margin: '5px'}}
                    control={control}
                />
                <FormControl variant="standard" style={{width: '200px', margin: '5px'}}>
                    <InputLabel>Alles gekoppeld</InputLabel>
                    <Controller
                        control={control}
                        name="allCyclistsLinked"
                        render={({field, onChange}) => (
                            <Select
                                label="Alles gekoppeld"
                                {...field}
                            >
                                <MenuItem value="-1">- Alle -</MenuItem>
                                <MenuItem value="1">Ja</MenuItem>
                                <MenuItem value="0">Nee</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormControl variant="standard" style={{width: '200px', margin: '5px'}}>
                    <InputLabel>Betaald</InputLabel>
                    <Controller
                        control={control}
                        name="payed"
                        render={({field, onChange}) => (
                            <Select
                                label="Betaald"
                                {...field}
                            >
                                <MenuItem value="-1">- Alle -</MenuItem>
                                <MenuItem value="1">Ja</MenuItem>
                                <MenuItem value="0">Nee</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <Button style={{marginLeft: '10px'}} size="small" type="submit" variant="contained" color="primary">
                    Filter
                </Button>
            </FormGroup>
        </form>
    );
}

export default CompetitorFilter;
