import { TOURNAMENT_SELECTED } from '../actions/tournament/types';

const selectedTournamentReducer = (selectedTournament = null, action) => {
    if (action.type === TOURNAMENT_SELECTED) {
        return action.payload;
    }

    return selectedTournament;
};

export default selectedTournamentReducer;
