import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from "react-hook-form";

const ControlledAutocomplete = ({ options = [], renderInput, getOptionLabel, onChange, control, defaultValue, name, renderOption, style}) => {
    return (
        <Controller
            render={({ field}) => (
                <Autocomplete
                    {...field}
                    style={style}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    renderInput={renderInput}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={(_, data) => {
                        return field.onChange(data)
                    }}
                />
            )}
            onChange={([, data]) => {
                return data
            }}
            defaultValue={defaultValue}
            name={name}
            control={control}
        />
    );
};

export default ControlledAutocomplete;
