import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Typography,
    Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import useRanking from '../../../hooks/useRanking';
import { fetchRankingResults } from '../../../actions/rankingResult';
import useResults from '../../../hooks/useResults';
import useRankingResults from '../../../hooks/useRankingResults';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const RankingStandings = () => {
    const {rankingId} = useParams();
    const {findById} = useRanking();
    const {findLastCalculated} = useResults();
    const {findByResult} = useRankingResults();
    const ranking = findById(rankingId);
    const lastCalculatedResult = findLastCalculated(rankingId)
    const dispatch = useDispatch();
    const rankingResults = findByResult(lastCalculatedResult ? lastCalculatedResult.id : -1)

    useEffect(() => {
        if (lastCalculatedResult) {
            dispatch(fetchRankingResults(lastCalculatedResult.id));
        }
    }, [lastCalculatedResult]);

    if (!rankingResults || !ranking) {
        return <div>Loading...</div>
    }

    const renderPrices = (nrOfPrices) => {
        let prices = [];
        for (var i = 0; i < nrOfPrices; i++) {
            prices.push(<EmojiEventsIcon style={{color: 'gold'}} />);
        }

        return <>{prices}</>;
    }

    const renderDiff = (diff) => {
        if (diff > 0) {
            return <KeyboardArrowUpIcon style={{color: 'green'}} />
        } else if (diff < 0) {
            return <KeyboardArrowDownIcon style={{color: 'red'}} />
        } else {
            return <></>
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">{ranking.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell><b>Naam</b></TableCell>
                                    <TableCell><b>Team</b></TableCell>
                                    <TableCell><b>Punten</b></TableCell>
                                    <TableCell><b>Prijzen</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rankingResults.map((rankingResult) => (
                                    <TableRow key={rankingResult.competitor} hover>
                                        <TableCell>{rankingResult.totalPosition}</TableCell>
                                        <TableCell>{renderDiff(rankingResult.totalDiff)}</TableCell>
                                        <TableCell>{rankingResult.competitorName}</TableCell>
                                        <TableCell>{rankingResult.competitorTeamName}</TableCell>
                                        <TableCell>{rankingResult.totalPoints}</TableCell>
                                        <TableCell>{rankingResult.totalPrices > 0 && (renderPrices(rankingResult.totalPrices))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default RankingStandings;
