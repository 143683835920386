import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { tournamentSelected } from '../../actions/tournament';
import TournamentDashboard from './dashboard/TournamentDashboard';
import TeamCreate from './team/teamCreate';
import TeamEdit from './team/teamEdit';
import TeamWrapper from './team/teamWrapper';
import {
    CREATE_COMPETITOR_ROUTE, CREATE_RANKING_ROUTE,
    CREATE_RESULT_ROUTE,
    CREATE_STAGE_ROUTE,
    CREATE_SUBLEAGUE_ROUTE,
    CREATE_TEAM_ROUTE,
    EDIT_COMPETITOR_ROUTE, EDIT_RANKING_ROUTE,
    EDIT_RESULT_ROUTE,
    EDIT_STAGE_ROUTE,
    EDIT_SUBLEAGUE_ROUTE,
    EDIT_TEAM_ROUTE,
    LIST_COMPETITOR_ROUTE, LIST_RANKING_ROUTE,
    LIST_RESULT_ROUTE,
    LIST_STAGE_ROUTE,
    LIST_SUBLEAGUE_ROUTE,
    LIST_TEAM_ROUTE, STANDING_RANKING_ROUTE, STANDINGS_RESULT_ROUTE
} from '../../routes';
import StageCreate from './stage/stageCreate';
import StageList from './stage/stageList';
import StageEdit from './stage/stageEdit';
import ResultCreate from './result/resultCreate';
import { fetchStages } from '../../actions/stage';
import { fetchResults } from '../../actions/result';
import { fetchTeams } from '../../actions/team';
import { fetchTournamentCyclists } from '../../actions/cyclist';
import ResultEdit from './result/resultEdit';
import { fetchResultCyclists } from '../../actions/resultCyclist';
import ResultWrapper from './result/resultWrapper';
import SubleagueEdit from './subleague/subleagueEdit';
import SubleagueCreate from './subleague/subleagueCreate';
import SubleagueWrapper from './subleague/subleagueWrapper';
import { fetchSubleagues } from '../../actions/subleague';
import { fetchCompetitors } from '../../actions/competitor';
import CompetitorWrapper from './competitor/competitorWrapper';
import CompetitorCreate from './competitor/competitorCreate';
import CompetitorEdit from './competitor/competitorEdit';
import RankingEdit from './ranking/rankingEdit';
import RankingCreate from './ranking/rankingCreate';
import { fetchRankings } from '../../actions/ranking';
import RankingList from './ranking/rankingList';
import { fetchRankingTypes } from '../../actions/rankingType';
import RankingStandings from './ranking/rankingStandings';
import ResultStandings from './result/resultStandings';

const TournamentWrapper = () => {
    const {id} = useParams();
    const {path} = useRouteMatch();
    const dispatch = useDispatch();
    const tournament = useSelector(state => state.tournaments[`/tournaments/${id}`]);
    const selectedTournament = useSelector(state => state.selectedTournament);

    useEffect(() => {
        if (!selectedTournament && tournament) {
            dispatch(tournamentSelected(tournament));
        }
    });

    useEffect(() => {
        if (!selectedTournament) {
            return;
        }

        dispatch(fetchTeams(selectedTournament.id));
        dispatch(fetchStages(selectedTournament.id));
        dispatch(fetchTournamentCyclists(selectedTournament.id));
        dispatch(fetchResults(selectedTournament.id));
        dispatch(fetchResultCyclists(selectedTournament.id));
        dispatch(fetchSubleagues(selectedTournament.id));
        dispatch(fetchCompetitors(selectedTournament.id));
        dispatch(fetchRankings(selectedTournament.id));
        dispatch(fetchRankingTypes(selectedTournament.id));
    }, [selectedTournament])

    return (
        <>
            <Switch>
                <Route path={`${path}/dashboard`} component={TournamentDashboard}></Route>

                <Route path={LIST_TEAM_ROUTE} exact component={TeamWrapper}></Route>
                <Route path={CREATE_TEAM_ROUTE} component={TeamCreate}></Route>
                <Route path={EDIT_TEAM_ROUTE} component={TeamEdit}></Route>

                <Route path={LIST_STAGE_ROUTE} exact component={StageList}></Route>
                <Route path={CREATE_STAGE_ROUTE} component={StageCreate}></Route>
                <Route path={EDIT_STAGE_ROUTE} component={StageEdit}></Route>

                <Route path={CREATE_RANKING_ROUTE} component={RankingCreate}></Route>
                <Route path={EDIT_RANKING_ROUTE} component={RankingEdit}></Route>
                <Route path={STANDING_RANKING_ROUTE} component={RankingStandings}></Route>
                <Route path={LIST_RANKING_ROUTE} component={RankingList}></Route>

                <Route path={EDIT_RESULT_ROUTE} component={ResultEdit}></Route>
                <Route path={CREATE_RESULT_ROUTE} component={ResultCreate}></Route>
                <Route path={STANDINGS_RESULT_ROUTE} component={ResultStandings}></Route>
                <Route path={LIST_RESULT_ROUTE} component={ResultWrapper}></Route>

                <Route path={EDIT_SUBLEAGUE_ROUTE} component={SubleagueEdit}></Route>
                <Route path={CREATE_SUBLEAGUE_ROUTE} component={SubleagueCreate}></Route>
                <Route path={LIST_SUBLEAGUE_ROUTE} component={SubleagueWrapper}></Route>

                <Route path={EDIT_COMPETITOR_ROUTE} component={CompetitorEdit}></Route>
                <Route path={CREATE_COMPETITOR_ROUTE} component={CompetitorCreate}></Route>
                <Route path={LIST_COMPETITOR_ROUTE} component={CompetitorWrapper}></Route>
            </Switch>
        </>);

}

export default TournamentWrapper;
