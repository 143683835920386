import { mapKeys } from 'lodash';
import { FETCH_TOURNAMENTS } from '../actions/tournament/types';

const INITIAL_STATE = {}

const tournamentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TOURNAMENTS:
            return {...state, ...mapKeys(action.payload, '@id')};
        default:
            return state;
    }
}

export default tournamentReducer;
