import React from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { createStage } from '../../../actions/stage';
import StageForm from './stageForm';

const StageCreate = () => {
    const dispatch = useDispatch();

    const onSubmit = data => {
        dispatch(createStage(data));
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>Aanmaken</h1>
            <StageForm onSubmit={onSubmit}/>
        </Paper>
    );
}

export default StageCreate;
