import React from 'react';
import { Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import CyclistForm from './cyclistForm';
import { createCyclist } from '../../../../actions/cyclist';

const CyclistCreate = ({team}) => {
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(createCyclist({
            ...data,
            team: team['@id'],
            name: `${data.lastName}, ${data.firstName}`
        }));
    }

    return (
        <Paper style={{marginTop: '10px', padding: '20px'}}>
            <CyclistForm onSubmit={onSubmit}/>
        </Paper>
    );
};

export default CyclistCreate;
