import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest
} from '../../apis/poolManager';
import { CREATE_RANKING, DELETE_RANKING, EDIT_RANKING, FETCH_RANKING, FETCH_RANKINGS } from './types';
import history from '../../components/history';
import { createListRankingRoute, createListStageRoute } from '../../routes';

export const fetchRankings = tournamentId => async (dispatch) => {
    const response = await executeGetRequest(`/rankings?tournament=${tournamentId}`);

    dispatch({
        type: FETCH_RANKINGS,
        payload: response.data['hydra:member']
    });
}

export const fetchRanking = id => async dispatch => {
    const response = await executeGetRequest(`/rankings/${id}`);

    dispatch({
        type: FETCH_RANKING,
        payload: response.data
    });
}

export const createRanking = formValues => async (dispatch, getState) => {
    const response = await executePostRequest(`/rankings`, {
        ...formValues,
        'tournament': `/tournaments/${getState().selectedTournament.id}`
    })

    dispatch({
        type: CREATE_RANKING,
        payload: response.data
    });

    history.push(createListRankingRoute(getState().selectedTournament.id));
}

export const editRanking = (id, formValues) => async (dispatch, getState) => {
    const response = await executePatchRequest(`/rankings/${id}`, {
        ...formValues
    }, {
        headers: {
            'Content-Type': 'application/merge-patch+json'
        }
    });

    dispatch({
        type: EDIT_RANKING,
        payload: response.data
    });

    history.push(createListRankingRoute(getState().selectedTournament.id));
}

export const deleteRanking = ranking => async dispatch => {
    await executeDeleteRequest(`/rankings/${ranking.id}`);

    dispatch({
        type: DELETE_RANKING,
        payload: ranking['@id']
    });
}

