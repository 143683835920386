import React from 'react';
import useCyclists from '../../hooks/useCyclists';
import { TextField } from '@material-ui/core';
import ControlledAutocomplete from './ControlledAutocomplete';

const CyclistSelect = ({control, style}) => {
    const {findForCurrentTournament} = useCyclists();
    const cyclists = findForCurrentTournament();

    const options = [];
    cyclists.map((cyclist) => {
        return options.push({value: cyclist['@id'], text: cyclist.fullName});
    });

    options.push({value: '', text: ''});

    return <ControlledAutocomplete
        style={style}
        control={control}
        name="cyclist"
        options={options}
        getOptionLabel={(option) => option.text || ""}
        renderInput={(params) => {
            return <TextField {...params} label="Renners"/>
        }}
    />
};

export default CyclistSelect;
