import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Text from '../../shared/Text';
import { formatDate } from '../../../helpers/dateHelper';

const RankingForm = ({onSubmit, defaultValues}) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <TextField
                name="name"
                label="Naam"
                style={{marginBottom: '20px'}}
                size="small"
                fullWidth
                required
                {...register('name')}
                error={!!errors?.name}
                helperText={errors?.name?.message}
            />
            <Button size="small" type="submit" variant="contained" color="primary">
                Opslaan
            </Button>
        </form>
    );
};

const schema = yup.object().shape({
    name: yup.string().required('Naam is verplicht'),
});

export default RankingForm;
