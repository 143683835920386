import { mapKeys, omit } from 'lodash';
import { FETCH_RANKING_RESULT } from '../actions/rankingResult/types';

const INITIAL_STATE = {}

const rankingResultsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_RANKING_RESULT:
            return {
                ...state,
                ...mapKeys(action.payload, '@id'),
            };
        default:
            return state;
    }
}

export default rankingResultsReducer;
