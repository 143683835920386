import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useRanking = () => {
    const state = useSelector((state) => state.rankings);
    const selectedTournament = useSelector((state) => state.selectedTournament);
    const [rankings, setRankings] = useState(Object.values(state));

    useEffect(() => {
        setRankings(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[`/rankings/${id}`];
    }

    function findForCurrentTournament() {
        return rankings.filter(stage => stage.tournament == selectedTournament['@id'])
    }

    return {
        rankings,
        findById,
        findForCurrentTournament,
    };
};

export default useRanking;
