import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { createCreateResultRoute } from '../../../routes';
import AddButton from '../../shared/addButton';
import ResultList from './resultList';
import ResultCyclistList from './resultCyclist/resultCyclistList';

const ResultWrapper = () => {
    const {tournamentId} = useParams();
    const [selectedResult, setSelectedResult] = useState(null);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Uitslagen</Typography>
                </Grid>
                <Grid item xs={selectedResult ? 7 : 12}>
                    <ResultList selectedTeam={selectedResult} onResultSelected={(result) => setSelectedResult(result)}/>
                </Grid>
                <Grid item xs={5}>
                    {selectedResult && <ResultCyclistList selectedResult={selectedResult}/>}
                </Grid>
            </Grid>
            <AddButton to={createCreateResultRoute(tournamentId)}/>
        </>
    )
}

export default ResultWrapper;
