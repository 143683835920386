import { mapKeys, omit } from 'lodash';
import { CREATE_RANKING, DELETE_RANKING, EDIT_RANKING, FETCH_RANKING, FETCH_RANKINGS } from '../actions/ranking/types';

const INITIAL_STATE = {}

const rankingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_RANKINGS:
            return {...state, ...mapKeys(action.payload, '@id')};
        case FETCH_RANKING:
        case CREATE_RANKING:
        case EDIT_RANKING:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_RANKING:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default rankingReducer;
