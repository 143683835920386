import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useRankingResults = () => {
    const state = useSelector((state) => state.rankingResults);
    const [rankingResults, setRankingResults] = useState(Object.values(state));

    useEffect(() => {
        setRankingResults(Object.values(state));
    }, [state]);

    function findByResult(resultId, orderBy = 'total') {
        const orderByFunction = orderBy === 'total' ? orderByTotal : orderByResult;

        return rankingResults
            .sort(orderByFunction)
            .filter(rankingResult => rankingResult.result['@id'] === `/results/${resultId}`)
    }

    function orderByTotal(a, b) {
        if (a.totalPosition < b.totalPosition) {
            return -1;
        }
        if (a.totalPosition > b.totalPosition) {
            return 1;
        }

        return 0;
    }

    function orderByResult(a, b) {
        if (a.resultPosition < b.resultPosition) {
            return -1;
        }
        if (a.resultPosition > b.resultPosition) {
            return 1;
        }

        return 0;
    }

    return {
        rankingResults,
        findByResult,
    };
};

export default useRankingResults;
