import React from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import ResultForm from './resultForm';
import { createResult } from '../../../actions/result';

const ResultCreate = () => {
    const dispatch = useDispatch();

    const onSubmit = data => {


        dispatch(createResult(data));
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>Aanmaken</h1>
            <ResultForm onSubmit={onSubmit}/>
        </Paper>
    );
};

export default ResultCreate;
