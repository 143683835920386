import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import ResultForm from './resultForm';
import { calculateResult, editResult } from '../../../actions/result';
import { useParams } from 'react-router-dom';
import useResults from '../../../hooks/useResults';
import { Button, Grid, Typography } from '@material-ui/core';
import ResultCyclistList from './resultCyclist/resultCyclistList';
import ResultCyclistCreate from './resultCyclist/resultCyclistCreate';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import useRankingTypes from '../../../hooks/useRankingTypes';
import useResultCyclists from '../../../hooks/useResultCyclists';
import CircularProgress from '@material-ui/core/CircularProgress';

const ResultEdit = () => {
    const [loading, setLoading] = useState(false);
    const {resultId} = useParams();
    const {findById} = useResults();
    const {findRankingTypeById} = useRankingTypes();
    const {findByResult} = useResultCyclists();
    const dispatch = useDispatch();
    const result = findById(resultId);

    if (!result) {
        return 'Loading...';
    }

    const onSubmit = data => {
        dispatch(editResult(resultId, data));
    }

    const onCalculate = () => {
        setLoading(true);
        dispatch(calculateResult(result));
    }

    const rankingType = findRankingTypeById(result.rankingType);
    const resultCyclists = findByResult(result);
    const allPositionsFilled = rankingType.points.length === resultCyclists.length;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Typography variant="h4">{result.displayName}</Typography>
                </Grid>
                {(!result.calculated && allPositionsFilled) && (
                    <Grid item xs={2} container justify="flex-end">
                        <Button
                            color="primary"
                            variant="outlined"
                            disabled={loading}
                            onClick={() => onCalculate()} startIcon={<SettingsOutlinedIcon/>}
                        >
                            Berekenen {loading && (<CircularProgress style={{marginLeft: '5px'}} color="inherit" size={20} />)}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={6}>
                    <Paper style={{padding: '20px'}}>
                        <ResultForm onSubmit={onSubmit} defaultValues={result}/>
                    </Paper>
                    {!allPositionsFilled && (
                        <ResultCyclistCreate result={result}/>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <ResultCyclistList selectedResult={result} allowDelete/>
                </Grid>
            </Grid>
        </>
    );
};

export default ResultEdit;
