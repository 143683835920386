import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ResultCyclistForm from './resultCycllistForm';
import { createResultCyclist } from '../../../../actions/resultCyclist';

const ResultCyclistCreate = ({result}) => {
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(createResultCyclist({
            result: result['@id'],
            cyclist: data.cyclist.value,
            position: data.position
        }));
    }

    const defaultValues = {
        cyclist: null
    }

    return (
        <Paper style={{marginTop: '10px', padding: '20px'}}>
            <ResultCyclistForm result={result} onSubmit={onSubmit} defaultValues={defaultValues}/>
        </Paper>
    );
};

export default ResultCyclistCreate;
