import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCompetitorCyclists = () => {
    const state = useSelector((state) => state.competitorCyclist);
    const [competitorCyclists, setcompetitorCyclists] = useState(Object.values(state));

    useEffect(() => {
        setcompetitorCyclists(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[`/competitor_cyclist/${id}`];
    }

    function findByCompetitor(competitor) {
        return competitorCyclists
            .filter(competitorCyclist => competitorCyclist.competitor === competitor['@id'])
            .sort(orderByNumber);
    }

    function findNrOfCyclists(competitor) {
        return findByCompetitor(competitor)
            .filter(competitorCyclist => !competitorCyclist.reserve)
            .length;
    }

    function findNrOfReserves(competitor) {
        return findByCompetitor(competitor)
            .filter(competitorCyclist => competitorCyclist.reserve)
            .length;
    }

    function orderByNumber(a, b) {
        if (a.number < b.number) {
            return -1;
        }
        if (a.number > b.number) {
            return 1;
        }

        return 0;
    }

    return {
        competitorCyclists,
        findNrOfCyclists,
        findNrOfReserves,
        findById,
        findByCompetitor,
    };
};

export default useCompetitorCyclists;
