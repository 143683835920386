import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import useStages from '../../../hooks/useStages';
import useRankingTypes from '../../../hooks/useRankingTypes';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControl from '@material-ui/core/FormControl';

const ResultForm = ({onSubmit, defaultValues}) => {
    const { register, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const {findForCurrentTournament} = useStages();
    const stages = findForCurrentTournament();

    const stageOptions = [];
    stages.map((stage) => {
        return stageOptions.push({value: stage['@id'], text: `${stage.from} / ${stage.to}`});
    });

    const {findRankingTypesForCurrentTournament} = useRankingTypes();
    const rankingTypes = findRankingTypesForCurrentTournament();

    const rankingTypeOptions = [];
    rankingTypes.map((rankingType) => {
        return rankingTypeOptions.push({value: rankingType['@id'], text: rankingType.rankingName});
    });

    if (!stageOptions || !rankingTypeOptions) {
        return <div>Loading...</div>
    }

    const onSubmitClick = (data) => {
        data = {
            ...data,
            number: parseInt(data.number)
        }

        onSubmit(data);
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitClick)}>
            <TextField
                name="number"
                label="Nummer"
                style={{marginBottom: '20px'}}
                size="small"
                fullWidth
                required
                {...register('number')}
                error={!!errors?.number}
                helperText={errors?.number?.message}
            />
            <FormControl
                fullWidth
                style={{marginBottom: '20px'}}
            >
                <InputLabel>Type</InputLabel>
                <Controller
                    control={control}
                    name="rankingType"
                    defaultValue={''}
                    render={({field}) => (
                        <Select
                            label="Type"
                            {...field}
                            error={!!errors?.rankingType}
                        >
                            {rankingTypeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
                <FormHelperText error={true}>{errors?.rankingType && errors?.rankingType.message}</FormHelperText>
            </FormControl>
            <FormControl
                fullWidth
                style={{marginBottom: '20px'}}
            >
                <InputLabel>Etappe</InputLabel>
                <Controller
                    control={control}
                    name="stage"
                    defaultValue={''}
                    render={({field}) => (
                        <Select {...field} fullWidth style={{marginBottom: '20px'}}>
                            {stageOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
            <Button type="submit" variant="contained" color="primary">
                Opslaan
            </Button>
        </form>
    );
};

const schema = yup.object().shape({
    rankingType: yup.string().required('Type is verplicht'),
});

export default ResultForm;
