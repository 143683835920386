import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const TeamForm = ({onSubmit, defaultValues}) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <TextField
                name="name"
                label="Naam"
                error={!!errors?.name}
                helperText={errors?.name?.message}
                {...register('name')}
                style={{marginBottom: '20px'}}
                size="small"
                fullWidth
                required
            />
            <TextField
                error={!!errors?.abbreviation}
                helperText={errors?.abbreviation?.message}
                {...register('abbreviation')}
                style={{marginBottom: '20px'}}
                name="abbreviation"
                label="Afkorting"
                size="small"
                fullWidth
                required
            />
            <TextField
                {...register('country')}
                style={{marginBottom: '20px'}}
                name="country"
                label="Land"
                size="small"
                fullWidth
            />
            <TextField
                {...register('imageUrl')}
                style={{marginBottom: '20px'}}
                name="imageUrl"
                label="Afbeelding"
                size="small"
                fullWidth
            />
            <Button size="small" type="submit" variant="contained" color="primary">
                Opslaan
            </Button>
        </form>
    );
};

const schema = yup.object().shape({
    name: yup.string().required('Naam is verplicht'),
    abbreviation: yup.string().max(3, 'Maximaal 3 karakters').required('Afkorting is verplicht'),
});

export default TeamForm;
