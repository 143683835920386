import { mapKeys, omit } from 'lodash';
import { CREATE_COMPETITOR, DELETE_COMPETITOR, EDIT_COMPETITOR, FETCH_COMPETITORS } from '../actions/competitor/types';

const INITIAL_STATE = {}

const competitorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_COMPETITORS:
            return {...state, ...mapKeys(action.payload, '@id')};
        case EDIT_COMPETITOR:
        case CREATE_COMPETITOR:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_COMPETITOR:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default competitorReducer;
