import { mapKeys, omit } from 'lodash';
import {
    CREATE_COMPETITOR_CYCLIST,
    DELETE_COMPETITOR_CYCLIST, EDIT_COMPETITOR_CYCLIST,
    FETCH_COMPETITOR_CYCLISTS
} from '../actions/competitorCyclist/types';
import { EDIT_COMPETITOR } from '../actions/competitor/types';

const INITIAL_STATE = {}

const resultReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_COMPETITOR_CYCLISTS:
            return {...state, ...mapKeys(action.payload, '@id')};
        case EDIT_COMPETITOR_CYCLIST:
        case CREATE_COMPETITOR_CYCLIST:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_COMPETITOR_CYCLIST:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default resultReducer;
