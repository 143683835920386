import { AUTHENTICATED, BAD_CREDENTIALS, NOT_AUTHENTICATED } from '../actions/auth/types';

const INITIAL_STATE = {
    authChecked: false,
    loggedIn: false,
    message: ''
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                authChecked: true,
                loggedIn: true,
                message: ''
            };
        case NOT_AUTHENTICATED:
            return {
                authChecked: true,
                loggedIn: false,
                message: ''
            };
        case BAD_CREDENTIALS:
            return {
                authChecked: true,
                loggedIn: false,
                message: 'Bad credentials'
            };
        default:
            return state;
    }
}

export default authReducer;
