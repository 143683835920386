import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from '@material-ui/core';
import useCompetitors from '../../../hooks/useCompetitors';
import CompetitorTableRow from './competitorTableRow';
import CompetitorFilter from './competitorFilter';

const CompetitorList = ({selectedCompetitor, onCompetitorSelected, closeList}) => {
    const {tournamentId} = useParams();
    const [filterValues, setFilterValues] = useState({
        payed: -1,
        allCyclistsLinked: -1,
        cyclist: null
    });
    const {findForCurrentTournament} = useCompetitors();
    let competitors = findForCurrentTournament(tournamentId);

    if (!competitors) {
        return <div>Loading...</div>
    }

    const onFilter = (data) => {
        setFilterValues(data);
        closeList();
    }

    if (filterValues.payed !== -1) {
        competitors = competitors.filter((competitor) => (competitor.payed ? 1 : 0) === filterValues.payed);
    }

    if (filterValues.cyclist) {
        competitors = competitors.filter((competitor) => competitor.cyclists.includes(filterValues.cyclist));
    }

    if (filterValues.allCyclistsLinked !== -1) {
        competitors = competitors.filter((competitor) => (competitor.allCyclistsLinked ? 1 : 0) === filterValues.allCyclistsLinked);
    }

    return (
        <>
            <CompetitorFilter defaultValues={filterValues} onSubmit={onFilter}/>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell><b>Naam</b></TableCell>
                            <TableCell><b>Team naam</b></TableCell>
                            <TableCell><b>Plaats</b></TableCell>
                            <TableCell><b>Alles gekoppeld</b></TableCell>
                            <TableCell><b>Leeftijd</b></TableCell>
                            <TableCell><b>Renners</b></TableCell>
                            <TableCell><b>Betaald</b></TableCell>
                            <TableCell width="15px"></TableCell>
                            <TableCell width="15px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {competitors.map((competitor, index) => (
                            <CompetitorTableRow
                                number={index + 1}
                                key={competitor.id}
                                onCompetitorSelected={onCompetitorSelected}
                                selected={selectedCompetitor === competitor}
                                competitor={competitor}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default CompetitorList;
