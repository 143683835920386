import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteTeam } from '../../../actions/team';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Avatar } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { createEditTeamRoute } from '../../../routes';
import useTeams from '../../../hooks/useTeams';

const TeamList = ({selectedTeam, onTeamSelected}) => {
    const {tournamentId} = useParams();
    const {findByTournament} = useTeams();
    const dispatch = useDispatch();
    const teams = findByTournament(tournamentId);

    if (!teams) {
        return <div>Loading...</div>
    }

    const onDeleteClicked = (team) => {
        dispatch(deleteTeam(team));
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell><b>Naam</b></TableCell>
                            <TableCell><b>Afkorting</b></TableCell>
                            <TableCell><b>Land</b></TableCell>
                            <TableCell width="15px"></TableCell>
                            <TableCell width="15px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teams.map((team) => (
                            <TableRow key={team.id} hover onClick={() => onTeamSelected(team)}
                                      selected={selectedTeam === team}>
                                <TableCell><Avatar alt={team.name} src={team.imageUrl}/></TableCell>
                                <TableCell>{team.name}</TableCell>
                                <TableCell>{team.abbreviation}</TableCell>
                                <TableCell>{team.country}</TableCell>
                                <TableCell>
                                    <RouterLink to={createEditTeamRoute(tournamentId, team.id)}>
                                        <EditIcon color="primary"/>
                                    </RouterLink>
                                </TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        cursor="pointer"
                                        color="primary"
                                        onClick={() => onDeleteClicked(team)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default TeamList;
