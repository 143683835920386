import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import SubleagueCompetitorTableRow from './subleagueCompetitorTableRow';
import { useDispatch } from 'react-redux';
import { editSubleague } from '../../../../actions/subleague';

const SubleagueCompetitorList = ({subleague, allowDelete}) => {
    const dispatch = useDispatch();

    const onDeleteClicked = (competitorToDelete) => {
        const updatedCompetitors = subleague.competitors.filter((competitor) => {
            return competitor !== competitorToDelete['@id'];
        })

        dispatch(editSubleague(subleague.id, {
            competitors: updatedCompetitors
        }));
    }

    return (
        <>
            <TableContainer style={{position: "sticky", top: '75px'}} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Naam</b></TableCell>
                            <TableCell><b>Team</b></TableCell>
                            {allowDelete && <TableCell width="15px"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subleague.competitors.map((competitorId) => (
                            <SubleagueCompetitorTableRow
                                key={competitorId}
                                competitorId={competitorId}
                                allowDelete={allowDelete}
                                onDeleteClicked={onDeleteClicked}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default SubleagueCompetitorList;
