import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { createCreateSubleagueRoute } from '../../../routes';
import AddButton from '../../shared/addButton';
import SubleagueList from './subleagueList';
import SubleagueCompetitorList from './subleagueCompetitor/subleagueCompetitorList';

const SubleagueWrapper = () => {
    const {tournamentId} = useParams();
    const [selectedSubleague, setSelectedSubleague] = useState(null);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Subleagues</Typography>
                </Grid>
                <Grid item xs={selectedSubleague ? 8 : 12}>
                    <SubleagueList selectedSubleague={selectedSubleague} onSubleagueSelected={(subleague) => setSelectedSubleague(subleague)}/>
                </Grid>
                <Grid item xs={4}>
                    {selectedSubleague && <SubleagueCompetitorList subleague={selectedSubleague}/>}
                </Grid>
            </Grid>
            <AddButton to={createCreateSubleagueRoute(tournamentId)}/>
        </>
    )
}

export default SubleagueWrapper;
