import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { deleteCompetitor } from '../../../actions/competitor';
import { useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { createEditCompetitorRoute } from '../../../routes';

const CompetitorTableRow = ({competitor, onCompetitorSelected, selected, number}) => {
    const dispatch = useDispatch();
    const {tournamentId} = useParams();

    const onDeleteClicked = () => {
        dispatch(deleteCompetitor(competitor));
    }

    const cyclists = competitor.cyclists.filter((cyclist) => {
        return cyclist && !cyclist.reserve;
    })

    return (
        <TableRow hover onClick={() => onCompetitorSelected(competitor)} selected={selected}>
            <TableCell>{number}</TableCell>
            <TableCell>{competitor.name}</TableCell>
            <TableCell>{competitor.teamName}</TableCell>
            <TableCell>{competitor.city}</TableCell>
            <TableCell>{competitor.allCyclistsLinked ? <CheckIcon/> : <ClearIcon/>}</TableCell>
            <TableCell>{competitor.age}</TableCell>
            <TableCell>{cyclists.length}</TableCell>
            <TableCell>{competitor.payed ? <CheckIcon/> : <ClearIcon/>}</TableCell>
            <TableCell>
                <RouterLink to={createEditCompetitorRoute(tournamentId, competitor.id)}>
                    <EditIcon color="primary"/>
                </RouterLink>
            </TableCell>
            <TableCell>
                <DeleteIcon
                    cursor="pointer"
                    color="primary"
                    onClick={() => onDeleteClicked(competitor)}
                />
            </TableCell>
        </TableRow>
    );
}

export default CompetitorTableRow;
