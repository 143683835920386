import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Container, MuiThemeProvider, CssBaseline } from '@material-ui/core';
import Header from './Header';
import theme from '../theme';
import history from './history';
import moment from 'moment';
import localization from 'moment/locale/nl';
import Login from './Login';
import TournamentWrapper from './tournament/tournamentWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from '../actions/auth';

const App = () => {
    moment.updateLocale('nl', localization);
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);

    useEffect(() => {
        if (!authState.authChecked) {
            dispatch(checkAuth());
        }
    }, [authState]);

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            {authState.authChecked && authState.loggedIn && (
                <Router history={history}>
                    <>
                        <Header/>
                        <Container style={{marginTop: '100px'}}>
                            <Switch>
                                <Route path="/tournament/:id" component={TournamentWrapper}/>
                            </Switch>
                        </Container>
                    </>
                </Router>
            )}
            {authState.authChecked && !authState.loggedIn && (
                <Login />
            )}
        </MuiThemeProvider>
    );
}

export default App;
