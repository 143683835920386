import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import useResults from '../../../hooks/useResults';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
    createCreateResultRoute,
    createEditResultRoute,
    createStandingsRankingRoute,
    createStandingsResultRoute
} from '../../../routes';
import { Delete as DeleteIcon, Edit as EditIcon, FormatListNumbered } from '@material-ui/icons';
import { deleteResult } from '../../../actions/result';
import { useDispatch } from 'react-redux';

const ResultList = ({selected, onResultSelected}) => {
    const {tournamentId} = useParams();
    const {findForCurrentTournament} = useResults()
    const results = findForCurrentTournament();
    const dispatch = useDispatch();

    if (!results) {
        return <div>Loading...</div>
    }

    const onDeleteClicked = (result) => {
        dispatch(deleteResult(result));
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>#</b></TableCell>
                            <TableCell><b>Naam</b></TableCell>
                            <TableCell><b>Klassement</b></TableCell>
                            <TableCell><b>Berekend</b></TableCell>
                            <TableCell width="15px"></TableCell>
                            <TableCell width="15px"></TableCell>
                            <TableCell width="15px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((result, index) => (
                            <TableRow key={result.id} hover onClick={() => onResultSelected(result)}>
                                <TableCell>{result.number}</TableCell>
                                <TableCell>{result.displayName}</TableCell>
                                <TableCell>{result.rankingName}</TableCell>
                                <TableCell width={25}>{result.calculated ? <CheckIcon/> : <ClearIcon/>}</TableCell>
                                <TableCell>
                                {result.calculated && result.rankingTypeAllowResultPrices && (
                                    <RouterLink to={createStandingsResultRoute(tournamentId, result.id)}>
                                        <FormatListNumbered color="primary"/>
                                    </RouterLink>
                                )}
                                </TableCell>
                                <TableCell>
                                    <RouterLink to={createEditResultRoute(tournamentId, result.id)}>
                                        <EditIcon color="primary"/>
                                    </RouterLink>
                                </TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        cursor="pointer"
                                        color="primary"
                                        onClick={() => onDeleteClicked(result)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ResultList;
