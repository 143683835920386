import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Button,
    Switch,
    FormControlLabel,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import CyclistSelect from '../../../shared/CyclistSelect';
import useCyclists from '../../../../hooks/useCyclists';
import useCompetitorCyclists from '../../../../hooks/useCompetitorCyclists';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import FormControl from '@material-ui/core/FormControl';
import { map } from 'lodash';

const CompetitorCyclistForm = ({onSubmit, defaultValues, competitor}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const selectedTournament = useSelector(state => state.selectedTournament);
    const {findCyclistById} = useCyclists();
    const {findNrOfCyclists, findNrOfReserves} = useCompetitorCyclists();

    const { control, reset, handleSubmit, setValue } = useForm({
        defaultValues,
    });

    useEffect(() => {
        if (defaultValues) {
            let defaultOption = {value: '', text: ''};

            const cyclist = findCyclistById(defaultValues.cyclist);
            if (cyclist) {
                defaultOption = {value: cyclist['@id'], text: cyclist.fullName};
            }

            setValue('cyclist', defaultOption);
            setValue('number', defaultValues.number);
            setValue('name', defaultValues.name);
            setValue('reserve', defaultValues.reserve);
        }
    }, [defaultValues]);

    const onSubmitClicked = (data) => {
        setErrorMessage(null);
        
        reset();
        onSubmit(data);
    }

    const positionOptions = [];
    const totalCyclists = (selectedTournament.nrOfCyclists + selectedTournament.nrOfReserves);
    for (let i = 1; i <= totalCyclists; i++) {
        const label = i + (i > selectedTournament.nrOfCyclists ? ' (Reserve)' : '');

        positionOptions.push({value: i, text: label});
    }

    return (
        <>
            {errorMessage && (
                <Alert severity="error">{errorMessage}</Alert>
            )}
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitClicked)}>
                <FormControl
                    fullWidth
                    style={{marginBottom: '20px'}}
                >
                    <InputLabel>Positie</InputLabel>
                    <Controller
                        control={control}
                        name="number"
                        render={({field}) => (
                            <Select {...field}>
                                {positionOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
                <CyclistSelect
                    control={control}
                />
                <Button size="small" type="submit" variant="contained" color="primary" style={{marginTop: '20px'}}>
                    Opslaan
                </Button>
            </form>
        </>
    );
};

export default CompetitorCyclistForm;
