import axios from 'axios';

axios.interceptors.request.use(function (request) {
    const accessToken = localStorage.getItem('token');

    if (accessToken && !request.url.includes('authentication_token')) {
        request.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    return request;
}, function (error) {
    return Promise.reject(error);
});

export function executeGetRequest(path) {
    return axios.get(process.env.REACT_APP_BASE_URL + path);
}

export function executePostRequest(path, data) {
    return axios.post(process.env.REACT_APP_BASE_URL + path, data);
}

export function executePatchRequest(path, data) {
    return axios.patch(process.env.REACT_APP_BASE_URL + path, data, {
        headers: {
            'Content-Type': 'application/merge-patch+json'
        }
    });
}

export function executePutRequest(path, data) {
    return axios.put(process.env.REACT_APP_BASE_URL + path, data);
}

export function executeDeleteRequest(path) {
    return axios.delete(process.env.REACT_APP_BASE_URL + path);
}
