import React from 'react';
import { Avatar, TableCell, TableRow } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import useTeams from '../../../../hooks/useTeams';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const CompetitorCyclistTableRow = ({background, competitorCyclist, cyclist, expanded, allowDelete, onDeleteClicked, onSelected, selected}) => {
    const {findById} = useTeams();

    const team = cyclist ? findById(cyclist.team) : undefined;

    return (
        <TableRow hover onClick={() => onSelected && onSelected(competitorCyclist)} selected={selected} style={{background: background}}>
            <TableCell>{competitorCyclist.number}</TableCell>
            <TableCell>
                {
                    team && <Avatar style={{height: '25px', width: '25px'}} alt={team.name} src={team.imageUrl}/>
                }
            </TableCell>
            <TableCell>{cyclist && cyclist.fullName}</TableCell>
            {
                expanded && <TableCell>{competitorCyclist.name}</TableCell>
            }
            <TableCell>{competitorCyclist.reserve ? <CheckIcon/> : <ClearIcon/>}</TableCell>
            {
                allowDelete && <TableCell>
                    <DeleteIcon
                        cursor="pointer"
                        color="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteClicked(competitorCyclist)
                        }}
                    />
                </TableCell>
            }
        </TableRow>
    );
}

export default CompetitorCyclistTableRow;
