import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest
} from '../../apis/poolManager';
import { CREATE_STAGE, DELETE_STAGE, EDIT_STAGE, FETCH_STAGE, FETCH_STAGES } from './types';
import history from '../../components/history';
import { createListStageRoute } from '../../routes';

export const fetchStages = tournamentId => async (dispatch) => {
    const response = await executeGetRequest(`/stages?tournament=${tournamentId}`);

    dispatch({
        type: FETCH_STAGES,
        payload: response.data['hydra:member']
    });
}

export const fetchStage = id => async dispatch => {
    const response = await executeGetRequest(`/stages/${id}`);

    dispatch({
        type: FETCH_STAGE,
        payload: response.data
    });
}

export const createStage = formValues => async (dispatch, getState) => {
    const response = await executePostRequest(`/stages`, {
        ...formValues,
        'tournament': `/tournaments/${getState().selectedTournament.id}`
    })

    dispatch({
        type: CREATE_STAGE,
        payload: response.data
    });

    history.push(createListStageRoute(getState().selectedTournament.id));
}

export const editStage = (id, formValues) => async (dispatch, getState) => {
    const response = await executePatchRequest(`/stages/${id}`, {
        ...formValues
    }, {
        headers: {
            'Content-Type': 'application/merge-patch+json'
        }
    });

    dispatch({
        type: EDIT_STAGE,
        payload: response.data
    });

    history.push(createListStageRoute(getState().selectedTournament.id));
}

export const deleteStage = stage => async dispatch => {
    await executeDeleteRequest(`/stages/${stage.id}`);

    dispatch({
        type: DELETE_STAGE,
        payload: stage['@id']
    });
}

