import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editStage, fetchStage } from '../../../actions/stage';
import Paper from '@material-ui/core/Paper';
import StageForm from './stageForm';
import { useParams } from 'react-router-dom';

const StageEdit = () => {
    const {stageId} = useParams();
    const stage = useSelector(state => state.stages[`/stages/${stageId}`]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchStage(stageId));
    }, []);

    const onSubmit = data => {
        dispatch(editStage(stageId, data));
    }

    if (!stage) {
        return <div>Loading</div>
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>{`Van ${stage.from} naar ${stage.to}`}</h1>
            <StageForm onSubmit={onSubmit} defaultValues={stage}/>
        </Paper>
    );
};

export default StageEdit;
