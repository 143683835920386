import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest, 
    executePutRequest
} from '../../apis/poolManager';
import { CALCULATE_RESULT, CREATE_RESULT, DELETE_RESULT, EDIT_RESULT, FETCH_RESULTS } from './types';
import history from '../../components/history';
import { createListResultRoute, createStandingsResultRoute } from '../../routes';

export const fetchResults = (tournamentId) => async (dispatch) => {
    const response = await executeGetRequest(`/results?rankingType.ranking.tournament=${tournamentId}`);

    dispatch({
        type: FETCH_RESULTS,
        payload: response.data['hydra:member']
    });
}

export const createResult = formValues => async (dispatch, getState) => {
    const response = await executePostRequest(`/results`, {
        ...formValues,
        'stage': formValues.stage.length > 0 ? formValues.stage : null,
        'tournament': `/tournaments/${getState().selectedTournament.id}`
    })

    dispatch({
        type: CREATE_RESULT,
        payload: response.data
    });

    history.push(createListResultRoute(getState().selectedTournament.id));
}

export const editResult = (id, formValues) => async (dispatch, getState) => {
    const response = await executePatchRequest(`/results/${id}`, {
        ...formValues
    });

    dispatch({
        type: EDIT_RESULT,
        payload: response.data
    });

    history.push(createListResultRoute(getState().selectedTournament.id));
}

export const deleteResult = result => async (dispatch) => {
    await executeDeleteRequest(`/results/${result.id}`);

    dispatch({
        type: DELETE_RESULT,
        payload: result['@id']
    });
}

export const calculateResult = result => async (dispatch, getState) => {
    const response = await executePutRequest(`/results/${result.id}/calculate`, {});

    dispatch({
        type: CALCULATE_RESULT,
        payload: response.data
    });

    history.push(createStandingsResultRoute(getState().selectedTournament.id, result.id));
}

