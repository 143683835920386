import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import AddButton from '../../shared/addButton';
import CompetitorList from './competitorList';
import { createCreateCompetitorRoute } from '../../../routes';
import CompetitorCyclistList from './competitorCyclist/competitorCyclistList';

const CompetitorWrapper = () => {
    const {tournamentId} = useParams();
    const [selectedCompetitor, setSelectedCompetitor] = useState(null);

    const closeList = () => {
        setSelectedCompetitor(null)
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Deelnemers</Typography>
                </Grid>
                <Grid item xs={selectedCompetitor ? 8 : 12}>
                    <CompetitorList
                        selectedCompetitor={selectedCompetitor}
                        onCompetitorSelected={(competitor) => setSelectedCompetitor(competitor)}
                        closeList={closeList}
                    />
                </Grid>
                <Grid item xs={4}>
                    {selectedCompetitor && <CompetitorCyclistList competitor={selectedCompetitor}/>}
                </Grid>
            </Grid>
            <AddButton to={createCreateCompetitorRoute(tournamentId)}/>
        </>
    )
}

export default CompetitorWrapper;
