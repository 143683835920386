import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Typography } from '@material-ui/core';
import CompetitorForm from './competitorForm';
import { editCompetitor } from '../../../actions/competitor';
import useCompetitors from '../../../hooks/useCompetitors';
import CompetitorCyclistList from './competitorCyclist/competitorCyclistList';
import CompetitorCyclistForm from './competitorCyclist/competitorCyclistForm';
import {
    createCompetitorCyclist, deleteCompetitorCyclist,
    editCompetitorCyclist, fetchCompetitorCyclists,
} from '../../../actions/competitorCyclist';

const CompetitorEdit = () => {
    const {competitorId} = useParams();
    const [selectedCompetitorCyclist, setSelectedCompetitorCyclist] = useState({
        number: '',
        cyclist: {value: '', text: ''},
        reserve: false
    });
    const {findById} = useCompetitors();
    const competitor = findById(`/competitors/${competitorId}`);
    const dispatch = useDispatch();

    useEffect(() => {
        if (competitor) {
            dispatch(fetchCompetitorCyclists(competitor.id));
        }
    }, [competitor])

    if (!competitor) {
        return <div>Loading</div>
    }
    
    const onSubmit = (data) => {
        dispatch(editCompetitor(competitorId, data));
    }

    const onSubmitCompetitorCyclistForm = (data) => {
        data = {
            ...data,
            cyclist: data.cyclist.value,
            competitor: competitor['@id']
        }

        if (selectedCompetitorCyclist['@id']) {
            dispatch(editCompetitorCyclist(selectedCompetitorCyclist.id, data));
        } else {
            dispatch(createCompetitorCyclist(data));
        }

        setSelectedCompetitorCyclist({
            cyclist: {value: '', text: ''},
            reserve: false
        });
    }

    const onCompetitorCyclistSelect = (competitorCyclist) => {
        setSelectedCompetitorCyclist(competitorCyclist)
    }

    const onDeleteCompetitorCyclist = (data) => {
        dispatch(deleteCompetitorCyclist(data));
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">{competitor.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={{padding: '20px'}}>
                        <CompetitorForm onSubmit={onSubmit} defaultValues={competitor}/>
                    </Paper>
                    <Paper style={{marginTop: '10px', padding: '20px'}}>
                        <CompetitorCyclistForm
                            competitor={competitor}
                            onSubmit={onSubmitCompetitorCyclistForm}
                            defaultValues={selectedCompetitorCyclist}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <CompetitorCyclistList
                        competitor={competitor}
                        onSelected={onCompetitorCyclistSelect}
                        onDeleteClicked={onDeleteCompetitorCyclist}
                        competitorCyclist={selectedCompetitorCyclist}
                        expanded
                        allowDelete
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CompetitorEdit;
