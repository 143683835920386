import { mapKeys, omit } from 'lodash';
import { CREATE_CYCLIST, DELETE_CYCLIST, EDIT_CYCLIST, FETCH_CYCLISTS } from '../actions/cyclist/types';

const INITIAL_STATE = {}

const cyclistReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CYCLISTS:
            return {...state, ...mapKeys(action.payload, '@id')};
        case CREATE_CYCLIST:
        case EDIT_CYCLIST:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_CYCLIST:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default cyclistReducer;
