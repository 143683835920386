import history from '../../components/history';
import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest
} from '../../apis/poolManager';
import { createListSubleagueRoute } from '../../routes';
import { CREATE_SUBLEAGUE, DELETE_SUBLEAGUE, EDIT_SUBLEAGUE, FETCH_SUBLEAGUE, FETCH_SUBLEAGUES } from './types';

export const fetchSubleagues = (tournamentId) => async (dispatch) => {
    const response = await executeGetRequest(`/subleagues?tournament=${tournamentId}`);

    dispatch({
        type: FETCH_SUBLEAGUES,
        payload: response.data['hydra:member']
    });
}

export const fetchSubleague = (subleagueId) => async (dispatch) => {
    const response = await executeGetRequest(`/subleagues/${subleagueId}`);

    dispatch({
        type: FETCH_SUBLEAGUE,
        payload: response.data
    });
}

export const createSubleague = (formValues) => async (dispatch, getState) => {
    const response = await executePostRequest(`/subleagues`, {
        ...formValues,
        'tournament': `/tournaments/${getState().selectedTournament.id}`
    });

    dispatch({
        type: CREATE_SUBLEAGUE,
        payload: response.data
    });

    history.push(createListSubleagueRoute(getState().selectedTournament.id));
}

export const editSubleague = (id, formValues, redirect) => async (dispatch, getState) => {
    const response = await executePatchRequest(`/subleagues/${id}`, {
        ...formValues
    }, {
        headers: {
            'Content-Type': 'application/merge-patch+json'
        }
    });

    dispatch({
        type: EDIT_SUBLEAGUE,
        payload: response.data
    });

    if (redirect) {
        history.push(createListSubleagueRoute(getState().selectedTournament.id));
    }
}

export const deleteSubleague = (subleague) => async dispatch => {
    await executeDeleteRequest(`/subleagues/${subleague.id}`);

    dispatch({
        type: DELETE_SUBLEAGUE,
        payload: subleague['@id']
    });
}
