import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useResultCyclists = () => {
    const state = useSelector((state) => state.resultCyclists);
    const [resultCyclists, setResultCyclists] = useState(Object.values(state));

    useEffect(() => {
        setResultCyclists(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[`/result_cyclists/${id}`];
    }

    function findByResult(result) {
        return resultCyclists
            .sort(orderByPosition)
            .filter(resultCyclist => resultCyclist.result === result['@id'])
    }

    function orderByPosition( a, b ) {
        if ( a.position < b.position ){
            return -1;
        }
        if ( a.position > b.position ){
            return 1;
        }
        return 0;
    }

    return {
        resultCyclists,
        findById,
        findByResult,
    };
};

export default useResultCyclists;
