import { executeGetRequest } from '../../apis/poolManager';
import { FETCH_RANKING_TYPES } from './types';

export const fetchRankingTypes = tournamentId => async (dispatch) => {
    const response = await executeGetRequest(`/ranking_types?ranking.tournament=${tournamentId}`);

    dispatch({
        type: FETCH_RANKING_TYPES,
        payload: response.data['hydra:member']
    });
}
