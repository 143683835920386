import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Avatar } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { createEditSubleagueRoute, createEditTeamRoute } from '../../../routes';
import useSubleagues from '../../../hooks/useSubleagues';
import { deleteSubleague } from '../../../actions/subleague';

const SubleagueList = ({selectedSubleague, onSubleagueSelected}) => {
    const {tournamentId} = useParams();
    const {findForCurrentTournament} = useSubleagues();
    const dispatch = useDispatch();
    const subleagues = findForCurrentTournament();

    if (!subleagues) {
        return <div>Loading...</div>
    }

    const onDeleteClicked = (subleague) => {
        dispatch(deleteSubleague(subleague));
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell><b>Naam</b></TableCell>
                            <TableCell width="15px"></TableCell>
                            <TableCell width="15px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subleagues.map((subleague, index) => (
                            <TableRow
                                key={subleague.id}
                                hover
                                onClick={() => onSubleagueSelected(subleague)}
                                selected={selectedSubleague === subleague}
                            >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{subleague.name}</TableCell>
                                <TableCell>
                                    <RouterLink to={createEditSubleagueRoute(tournamentId, subleague.id)}>
                                        <EditIcon color="primary"/>
                                    </RouterLink>
                                </TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        cursor="pointer"
                                        color="primary"
                                        onClick={() => onDeleteClicked(subleague)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default SubleagueList;
