import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Typography,
    Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import { fetchRankingResults } from '../../../actions/rankingResult';
import useRankingResults from '../../../hooks/useRankingResults';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import useResults from '../../../hooks/useResults';

const ResultStandings = () => {
    const {resultId} = useParams();
    const dispatch = useDispatch();
    const {findByResult} = useRankingResults();
    const {findById} = useResults();
    const rankingResults = findByResult(resultId, 'result');
    const result = findById(resultId);

    useEffect(() => {
        dispatch(fetchRankingResults(resultId));
    }, [resultId]);

    if (!rankingResults) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">{result.displayName}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell><b>Naam</b></TableCell>
                                    <TableCell><b>Team</b></TableCell>
                                    <TableCell><b>Punten</b></TableCell>
                                    <TableCell><b>Dagprijs</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rankingResults.map((rankingResult) => (
                                    <TableRow key={rankingResult.competitor} hover>
                                        <TableCell>{rankingResult.resultPosition}</TableCell>
                                        <TableCell>{rankingResult.competitorName}</TableCell>
                                        <TableCell>{rankingResult.competitorTeamName}</TableCell>
                                        <TableCell>{rankingResult.resultPoints}</TableCell>
                                        <TableCell>{rankingResult.resultPrice && (<EmojiEventsIcon style={{color: 'gold'}} />) }</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default ResultStandings;
