import React from 'react';
import useResultCyclists from '../../../../hooks/useResultCyclists';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ResultCyclistTableRow from './resultCyclistTableRow';

const ResultCyclistList = ({selectedResult, allowDelete}) => {
    const {findByResult} = useResultCyclists();
    const resultCyclists = findByResult(selectedResult);

    return (
        <>
            <TableContainer style={{position: "sticky", top: '75px'}} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="15px"><b>Positie</b></TableCell>
                            <TableCell width="15px"><b>Team</b></TableCell>
                            <TableCell><b>Naam</b></TableCell>
                            {allowDelete && <TableCell width="15px"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultCyclists.map((resultCyclist) => (
                            <ResultCyclistTableRow key={resultCyclist.id} resultCyclist={resultCyclist} allowDelete={allowDelete}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default ResultCyclistList
