import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCompetitors = () => {
    const state = useSelector((state) => state.competitors);
    const selectedTournament = useSelector((state) => state.selectedTournament);
    const [competitors, setCompetitors] = useState(Object.values(state));

    useEffect(() => {
        setCompetitors(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[id];
    }

    function findForCurrentTournament() {
        return competitors
            .sort(orderByName)
            .filter(competitor => competitor.tournament == selectedTournament['@id'])
    }

    function orderByName(a, b) {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }

        return 0;
    }

    return {
        competitors,
        findById,
        findForCurrentTournament,
    };
};

export default useCompetitors;
