import history from '../../components/history';
import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest
} from '../../apis/poolManager';
import { CREATE_TEAM, DELETE_TEAM, EDIT_TEAM, FETCH_TEAM, FETCH_TEAMS } from './types';
import { createListTeamRoute } from '../../routes';

export const fetchTeams = (tournamentId) => async (dispatch) => {
    const response = await executeGetRequest(`/teams?tournament=${tournamentId}`);

    dispatch({
        type: FETCH_TEAMS,
        payload: response.data['hydra:member']
    });
}

export const fetchTeam = (teamId) => async (dispatch) => {
    const response = await executeGetRequest(`/teams/${teamId}`);

    dispatch({
        type: FETCH_TEAM,
        payload: response.data
    });
}

export const createTeam = (formValues) => async (dispatch, getState) => {
    const response = await executePostRequest(`/teams`, {
        ...formValues,
        'tournament': `/tournaments/${getState().selectedTournament.id}`
    });

    dispatch({
        type: CREATE_TEAM,
        payload: response.data
    });

    history.push(createListTeamRoute(getState().selectedTournament.id));
}

export const editTeam = (id, formValues) => async (dispatch, getState) => {
    const response = await executePatchRequest(`/teams/${id}`, {
        ...formValues
    });

    dispatch({
        type: EDIT_TEAM,
        payload: response.data
    });

    history.push(createListTeamRoute(getState().selectedTournament.id));
}

export const deleteTeam = (team) => async dispatch => {
    await executeDeleteRequest(`/teams/${team.id}`);

    dispatch({
        type: DELETE_TEAM,
        payload: team['@id']
    });
}
