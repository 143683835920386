import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCyclists = () => {
    const state = useSelector((state) => state.cyclists);
    const selectedTournament = useSelector((state) => state.selectedTournament);
    const [cyclists, setCyclists] = useState(Object.values(state));

    useEffect(() => {
        setCyclists(Object.values(state));
    }, [state]);

    function findCyclistById(id) {
        return state[id];
    }

    function findForCurrentTournament() {
        return cyclists.filter(cyclist => cyclist.tournament == selectedTournament['@id'])
    }

    return {
        cyclists,
        findCyclistById,
        findForCurrentTournament
    };
};

export default useCyclists;
