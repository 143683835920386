import React from 'react';
import { useDispatch,  } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Typography } from '@material-ui/core';
import SubleagueForm from './subleagueForm';
import useSubleagues from '../../../hooks/useSubleagues';
import { editSubleague } from '../../../actions/subleague';
import SubleagueCompetitorList from './subleagueCompetitor/subleagueCompetitorList';
import SubleagueCompetitorCreate from './subleagueCompetitor/subleagueCompetitorCreate';

const SubleagueEdit = () => {
    const {subleagueId} = useParams();
    const {findById} = useSubleagues();
    const subleague = findById(subleagueId);
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(editSubleague(subleagueId, data));
    }

    if (!subleague) {
        return <div>Loading</div>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">{subleague.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Paper style={{padding: '20px'}}>
                        <SubleagueForm onSubmit={onSubmit} defaultValues={subleague}/>
                    </Paper>
                    <SubleagueCompetitorCreate subleague={subleague}/>
                </Grid>
                <Grid item xs={6}>
                    <SubleagueCompetitorList subleague={subleague} allowDelete/>
                </Grid>
            </Grid>
        </>
    );
};

export default SubleagueEdit;
