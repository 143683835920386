import { executeGetRequest } from '../../apis/poolManager';
import { FETCH_TOURNAMENTS, FETCH_TOURNAMENT, TOURNAMENT_SELECTED } from './types';

export const tournamentSelected = (tournament) => dispatch => {
    dispatch({
        type: TOURNAMENT_SELECTED,
        payload: tournament
    });
}

export const fetchTournaments = () => async dispatch => {
    const response = await executeGetRequest('/tournaments');

    dispatch({
        type: FETCH_TOURNAMENTS,
        payload: response.data['hydra:member']
    });
}

export const fetchTournament = (id) => async dispatch => {
    const response = await executeGetRequest(`/tournaments/${id}`);

    dispatch({
        type: FETCH_TOURNAMENT,
        payload: response.data
    });
}
