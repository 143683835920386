import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useResults = () => {
    const state = useSelector((state) => state.results);
    const selectedTournament = useSelector((state) => state.selectedTournament);
    const [results, setResults] = useState(Object.values(state));

    useEffect(() => {
        setResults(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[`/results/${id}`];
    }

    function findLastCalculated(rankingId) {
        const filteredResults = results.filter(results => results.calculated && results.ranking === `/rankings/${rankingId}`)

        if (filteredResults.length === 0) return;

        return filteredResults.reduce(function (a, b) {
            return a.calculatedAt > b.calculatedAt ? a : b;
        })
    }

    function findForCurrentTournament() {
        return results.filter(result => result.tournamentId === selectedTournament['@id'])
    }

    return {
        results,
        findById,
        findLastCalculated,
        findForCurrentTournament,
    };
};

export default useResults;
