import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { createSubleague } from '../../../actions/subleague';
import SubleagueForm from './subleagueForm';

const SubleagueCreate = () => {
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(createSubleague(data));
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>Aanmaken</h1>
            <SubleagueForm onSubmit={onSubmit} />
        </Paper>
    );
};

export default SubleagueCreate;
