import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { TextField, Button } from '@material-ui/core';
import { formatDate } from '../../../helpers/dateHelper';

const StageForm = ({onSubmit, defaultValues}) => {
    defaultValues = {
        ...defaultValues,
        date: defaultValues ? formatDate(defaultValues.date, 'YYYY-MM-DD') : null,
    }

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const onPreSubmit = (data) => {
        data.number = parseInt(data.number);
        data.distance = parseInt(data.distance);

        onSubmit(data);
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onPreSubmit)}>
            <TextField
                name="number"
                id="number"
                label="Nummer"
                type="number"
                {...register('number')}
                style={{marginBottom: '20px'}}
                required
                fullWidth
            />
            <TextField
                name="date"
                id="date"
                label="Datum"
                type="date"
                {...register('date')}
                style={{marginBottom: '20px'}}
                InputLabelProps={{
                    shrink: true,
                }}
                required
                fullWidth
            />
            <TextField
                name="from"
                label="Van"
                error={!!errors?.from}
                helperText={errors?.from?.message}
                {...register('from')}
                style={{marginBottom: '20px'}}
                required
                fullWidth
            />
            <TextField
                name="to"
                label="Naar"
                error={!!errors?.to}
                helperText={errors?.to?.message}
                {...register('to')}
                style={{marginBottom: '20px'}}
                required
                fullWidth
            />
            <TextField
                name="distance"
                label="Afstand"
                error={!!errors?.distance}
                helperText={errors?.distance?.message}
                {...register('distance')}
                style={{marginBottom: '20px'}}
                fullWidth
            />
            <Button type="submit" variant="contained" color="primary">
                Opslaan
            </Button>
        </form>
    );
};

const schema = yup.object().shape({
    from: yup.string().required('Van is verplicht'),
    to: yup.string().required('Naar is verplicht'),
});

export default StageForm;
