import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Typography } from '@material-ui/core';
import { editTeam, fetchTeam } from '../../../actions/team';
import CyclistCreate from './cyclist/cyclistCreate';
import TeamForm from './teamForm';
import CyclistList from './cyclist/cyclistList';

const TeamEdit = () => {
    const {teamId} = useParams();
    const team = useSelector(state => state.teams[`/teams/${teamId}`]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTeam(teamId));
    }, []);

    const onSubmit = (data) => {
        dispatch(editTeam(teamId, data));
    }

    if (!team) {
        return <div>Loading</div>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">{team.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Paper style={{padding: '20px'}}>
                        <TeamForm onSubmit={onSubmit} defaultValues={team}/>
                    </Paper>
                    <CyclistCreate team={team}/>
                </Grid>
                <Grid item xs={6}>
                    <CyclistList selectedTeam={team} allowDelete/>
                </Grid>
            </Grid>
        </>
    );
};

export default TeamEdit;
