import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import useCompetitors from '../../../../hooks/useCompetitors';

const SubleagueCompetitorTableRow = ({competitorId, allowDelete, onDeleteClicked}) => {
    const {findById} = useCompetitors();
    const competitor = findById(competitorId);

    if (!competitor) {
        return <TableRow hover></TableRow>;
    }

    return (
        <TableRow hover>
            <TableCell>{competitor.name}</TableCell>
            <TableCell>{competitor.teamName}</TableCell>
            {
                allowDelete && <TableCell>
                    <DeleteIcon
                        cursor="pointer"
                        color="primary"
                        onClick={() => onDeleteClicked(competitor)}
                    />
                </TableCell>
            }
        </TableRow>
    );
}

export default SubleagueCompetitorTableRow;
