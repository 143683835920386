import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { fetchTournament } from '../../../actions/tournament';

const TournamentDashboard = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const tournament = useSelector(state => state.tournaments[params.id]);

    useEffect(() => {
        dispatch(fetchTournament(params.id))
    }, [params]);

    if (!tournament) {
        return <p>Loading...</p>
    }

    return (
        <>
            <Typography variant="h3">{tournament.name}</Typography>
        </>
    );
}

export default TournamentDashboard;
