import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest
} from '../../apis/poolManager';
import {
    FETCH_COMPETITOR_CYCLISTS,
    CREATE_COMPETITOR_CYCLIST,
    DELETE_COMPETITOR_CYCLIST,
    EDIT_COMPETITOR_CYCLIST
} from './types';

export const fetchCompetitorCyclists = (competitorId) => async (dispatch) => {
    const response = await executeGetRequest(`/competitor_cyclists?competitor=${competitorId}`);

    dispatch({
        type: FETCH_COMPETITOR_CYCLISTS,
        payload: response.data['hydra:member']
    });
}

export const createCompetitorCyclist = (formValues) => async (dispatch) => {
    const response = await executePostRequest(`/competitor_cyclists`, {
        ...formValues,
    });

    dispatch({
        type: CREATE_COMPETITOR_CYCLIST,
        payload: response.data
    });
}

export const editCompetitorCyclist = (id, formValues) => async (dispatch) => {
    const response = await executePatchRequest(`/competitor_cyclists/${id}`, {
        ...formValues
    });

    dispatch({
        type: EDIT_COMPETITOR_CYCLIST,
        payload: response.data
    });
}

export const deleteCompetitorCyclist = competitorCyclist => async dispatch => {
    await executeDeleteRequest(`/competitor_cyclists/${competitorCyclist.id}`);

    dispatch({
        type: DELETE_COMPETITOR_CYCLIST,
        payload: competitorCyclist['@id']
    });
}
