import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    Grid
} from '@material-ui/core';
import { deleteStage } from '../../../actions/stage';
import { createCreateStageRoute, createEditStageRoute } from '../../../routes';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { formatDate } from '../../../helpers/dateHelper';
import AddButton from '../../shared/addButton';
import useStages from '../../../hooks/useStages';

const StageList = () => {
    const {tournamentId} = useParams();
    const {findForCurrentTournament} = useStages();
    const dispatch = useDispatch();
    const stages = findForCurrentTournament();

    if (!stages) {
        return <div>Loading...</div>
    }

    const onDeleteClicked = (stage) => {
        dispatch(deleteStage(stage));
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Etappes</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell><b>Datum</b></TableCell>
                                    <TableCell><b>Van / Naar</b></TableCell>
                                    <TableCell><b>Afstand</b></TableCell>
                                    <TableCell width="15px"></TableCell>
                                    <TableCell width="15px"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stages.map((stage) => (
                                    <TableRow key={stage.id} hover>
                                        <TableCell>{stage.number}</TableCell>
                                        <TableCell>{formatDate(stage.date)}</TableCell>
                                        <TableCell>{stage.displayName}</TableCell>
                                        <TableCell>{stage.distance} km</TableCell>
                                        <TableCell>
                                            <RouterLink to={createEditStageRoute(tournamentId, stage.id)}>
                                                <EditIcon color="primary"/>
                                            </RouterLink>
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon
                                                cursor="pointer"
                                                color="primary"
                                                onClick={() => onDeleteClicked(stage)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <AddButton to={createCreateStageRoute(tournamentId)}/>
        </>
    );
}

export default StageList;
