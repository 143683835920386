import { AUTHENTICATED, BAD_CREDENTIALS, NOT_AUTHENTICATED } from './types';
import { executePostRequest } from '../../apis/poolManager';
import jwt_decode from 'jwt-decode';

export const loginUser = (formValues) => async (dispatch) => {
    try {
        const response = await executePostRequest(`/authentication_token`, {
            ...formValues
        });

        localStorage.setItem('token', response.data.token);

        dispatch({
            type: AUTHENTICATED,
            payload: response.data
        });
    } catch (e) {
        dispatch({
            type: BAD_CREDENTIALS,
        });
    }
};

export const logoutUser = () => dispatch => {
    localStorage.removeItem('token');

    dispatch({
        type: NOT_AUTHENTICATED,
    });
}

export const checkAuth = () => dispatch => {
    const token = localStorage.getItem('token');

    if (!token) {
        dispatch({
            type: NOT_AUTHENTICATED,
        });
    } else {
        const decodedToken = jwt_decode(token);

        if (Date.now() < decodedToken.exp * 1000) {
            dispatch({
                type: AUTHENTICATED,
            });
        } else {
            dispatch({
                type: NOT_AUTHENTICATED,
            });
        }
    }
}
