import { mapKeys, omit } from 'lodash';
import { CREATE_STAGE, DELETE_STAGE, EDIT_STAGE, FETCH_STAGE, FETCH_STAGES } from '../actions/stage/types';

const INITIAL_STATE = {}

const stageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_STAGES:
            return {...state, ...mapKeys(action.payload, '@id')};
        case FETCH_STAGE:
        case CREATE_STAGE:
        case EDIT_STAGE:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_STAGE:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default stageReducer;
