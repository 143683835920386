import {
    executeDeleteRequest,
    executeGetRequest,
    executePatchRequest,
    executePostRequest
} from '../../apis/poolManager';
import { CREATE_CYCLIST, DELETE_CYCLIST, EDIT_CYCLIST, FETCH_CYCLISTS } from './types';
import { EDIT_COMPETITOR } from '../competitor/types';
import history from '../../components/history';
import { createListCompetitorRoute } from '../../routes';

export const fetchTournamentCyclists = (tournamentId) => async (dispatch) => {
    const response = await executeGetRequest(`/cyclists?team.tournament=${tournamentId}`);

    dispatch({
        type: FETCH_CYCLISTS,
        payload: response.data['hydra:member']
    });
}

export const fetchCyclists = (teamId) => async (dispatch) => {
    const response = await executeGetRequest(`/teams/${teamId}/cyclists`);

    dispatch({
        type: FETCH_CYCLISTS,
        payload: response.data['hydra:member']
    });
}

export const createCyclist = (formValues) => async (dispatch) => {
    const response = await executePostRequest(`/cyclists`, formValues);

    dispatch({
        type: CREATE_CYCLIST,
        payload: response.data
    });
}

export const editCyclist = (id, formValues) => async (dispatch) => {
    const response = await executePatchRequest(`/cyclists/${id}`, {
        ...formValues
    });

    dispatch({
        type: EDIT_CYCLIST,
        payload: response.data
    });
}

export const deleteCyclist = (cyclist) => async (dispatch) => {
    await executeDeleteRequest(`/cyclists/${cyclist.id}`);

    dispatch({
        type: DELETE_CYCLIST,
        payload: cyclist['@id']
    });
}
