import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useTeams = () => {
    const state = useSelector((state) => state.teams);
    const [teams, setTeams] = useState(Object.values(state));

    useEffect(() => {
        setTeams(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[id];
    }

    function findByTournament(tournamentId) {
        return teams.filter((team) => {
            return team.tournament == `/tournaments/${tournamentId}`
        });
    }

    return {
        teams,
        findById,
        findByTournament,
    };
};

export default useTeams;
