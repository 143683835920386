import { executeGetRequest } from '../../apis/poolManager';
import { FETCH_RANKING_RESULT } from './types';

export const fetchRankingResults = resultId => async (dispatch) => {
    const response = await executeGetRequest(`/results/${resultId}/standings`);

    dispatch({
        type: FETCH_RANKING_RESULT,
        payload: response.data['hydra:member']
    });
}
