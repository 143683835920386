import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStages = () => {
    const state = useSelector((state) => state.stages);
    const selectedTournament = useSelector((state) => state.selectedTournament);
    const [stages, setStages] = useState(Object.values(state));

    useEffect(() => {
        setStages(Object.values(state));
    }, [state]);

    function findById(id) {
        return state[id];
    }

    function findForCurrentTournament() {
        return stages.filter(stage => stage.tournament == selectedTournament['@id'])
    }

    return {
        stages,
        findById,
        findForCurrentTournament,
    };
};

export default useStages;
