import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    Grid
} from '@material-ui/core';
import { deleteStage } from '../../../actions/stage';
import { createCreateRankingRoute, createEditRankingRoute, createEditStageRoute, createStandingsRankingRoute } from '../../../routes';
import { Delete as DeleteIcon, Edit as EditIcon, FormatListNumbered } from '@material-ui/icons';
import AddButton from '../../shared/addButton';
import useRanking from '../../../hooks/useRanking';
import { deleteRanking } from '../../../actions/ranking';

const RankingList = () => {
    const {tournamentId} = useParams();
    const {findForCurrentTournament} = useRanking();
    const dispatch = useDispatch();
    const rankings = findForCurrentTournament();

    if (!rankings) {
        return <div>Loading...</div>
    }

    const onDeleteClicked = (ranking) => {
        dispatch(deleteRanking(ranking));
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Klassementen</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell><b>Naam</b></TableCell>
                                    <TableCell width="15px"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rankings.map((ranking, index) => (
                                    <TableRow key={ranking.id} hover>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{ranking.name}</TableCell>
                                        <TableCell>
                                            <RouterLink to={createStandingsRankingRoute(tournamentId, ranking.id)}>
                                                <FormatListNumbered color="primary"/>
                                            </RouterLink>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default RankingList;
