import React from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import RankingForm from './rankingForm';
import { createRanking } from '../../../actions/ranking';

const RankingCreate = () => {
    const dispatch = useDispatch();

    const onSubmit = data => {
        dispatch(createRanking(data));
    }

    return (
        <Paper style={{padding: '20px'}}>
            <h1>Aanmaken</h1>
            <RankingForm onSubmit={onSubmit}/>
        </Paper>
    );
}

export default RankingCreate;
