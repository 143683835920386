import React from 'react';
import { Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import SubleagueCompetitorForm from './subleagueCompetitorForm';
import { editSubleague } from '../../../../actions/subleague';

const SubleagueCompetitorCreate = ({subleague}) => {
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(editSubleague(subleague.id, {
            competitors: [
                ...subleague.competitors,
                data.competitor.value
            ]
        }));
    }

    return (
        <Paper style={{marginTop: '10px', padding: '20px'}}>
            <SubleagueCompetitorForm onSubmit={onSubmit}/>
        </Paper>
    );
};

export default SubleagueCompetitorCreate;
