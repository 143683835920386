import { mapKeys, omit } from 'lodash';
import { CREATE_TEAM, DELETE_TEAM, EDIT_TEAM, FETCH_TEAM, FETCH_TEAMS } from '../actions/team/types';

const INITIAL_STATE = {}

const teamReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TEAMS:
            return {...state, ...mapKeys(action.payload, '@id')};
        case FETCH_TEAM:
        case EDIT_TEAM:
        case CREATE_TEAM:
            return {...state, [action.payload['@id']]: action.payload}
        case DELETE_TEAM:
            return omit(state, action.payload);
        default:
            return state;
    }
}

export default teamReducer;
