import { combineReducers } from 'redux';
import tournamentReducer from './tournamentReducer';
import selectedTournamentReducer from './selectedTournamentReducer';
import teamReducer from './teamReducer';
import cyclistReducer from './cyclistReducer';
import stageReducer from './stageReducer';
import resultReducer from './resultReducer';
import resultCyclistReducer from './resultCyclistReducer';
import subleagueReducer from './subleagueReducer';
import competitorReducer from './competitorReducer';
import rankingReducer from './rankingReducer';
import rankingTypeReducer from './rankingTypeReducer';
import competitorCyclistReducer from './competitorCyclistReducer';
import rankingResultsReducer from './rankingResultsReducer';
import authReducer from './authReducer';

export default combineReducers({
    tournaments: tournamentReducer,
    selectedTournament: selectedTournamentReducer,
    teams: teamReducer,
    cyclists: cyclistReducer,
    stages: stageReducer,
    results: resultReducer,
    resultCyclists: resultCyclistReducer,
    subleagues: subleagueReducer,
    competitors: competitorReducer,
    competitorCyclist: competitorCyclistReducer,
    rankings: rankingReducer,
    rankingTypes: rankingTypeReducer,
    rankingResults: rankingResultsReducer,
    auth: authReducer,
});
