import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { createCreateTeamRoute } from '../../../routes';
import TeamList from './teamList';
import CyclistList from './cyclist/cyclistList';
import AddButton from '../../shared/addButton';

const TeamWrapper = () => {
    const {tournamentId} = useParams();
    const [selectedTeam, setSelectedTeam] = useState(null);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Teams</Typography>
                </Grid>
                <Grid item xs={selectedTeam ? 8 : 12}>
                    <TeamList selectedTeam={selectedTeam} onTeamSelected={(team) => setSelectedTeam(team)}/>
                </Grid>
                <Grid item xs={4}>
                    {selectedTeam && <CyclistList selectedTeam={selectedTeam}/>}
                </Grid>
            </Grid>
            <AddButton to={createCreateTeamRoute(tournamentId)}/>
        </>
    )
}

export default TeamWrapper;
